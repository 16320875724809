import { color } from '@/utils/constants'
import type { Renderer } from '@googlemaps/markerclusterer'
import {
  GM_DEFAULT_MARKER_ANCHOR,
  GM_DEFAULT_MARKER_LABELORIGIN,
  GM_DEFAULT_MARKER_PATH,
  GM_DEFAULT_MARKER_SCALE,
  GM_LABEL_FONT_COLOR,
  GM_LABEL_FONT_FAMILY,
  GM_LABEL_FONT_SIZE,
  GM_LABEL_FONT_WEIGHT,
  GM_MARKER_COLOR
} from './constants'
import type { NNPlace } from '@/models/google-maps'

export function createMarkerIcon({
  url = '/map-pin-red.svg',
  anchor = new google.maps.Point(13, 40),
  labelOrigin = new google.maps.Point(13, 13),
  origin = new google.maps.Point(0, 0),
  size = new google.maps.Size(25, 40)
}: Partial<google.maps.Icon>): google.maps.Icon {
  return {
    url,
    anchor,
    labelOrigin,
    origin,
    scaledSize: size,
    size
  }
}

export function createMarkerSymbol({
  path = GM_DEFAULT_MARKER_PATH,
  anchor = GM_DEFAULT_MARKER_ANCHOR(),
  fillColor = color.red50,
  fillOpacity = 1,
  labelOrigin = GM_DEFAULT_MARKER_LABELORIGIN(),
  rotation = 0,
  scale = GM_DEFAULT_MARKER_SCALE,
  strokeColor = color.red50,
  strokeOpacity = 1,
  strokeWeight = 1
}: Partial<google.maps.Symbol>): google.maps.Symbol {
  return {
    path,
    anchor,
    fillColor,
    fillOpacity,
    labelOrigin,
    rotation,
    scale,
    strokeColor,
    strokeOpacity,
    strokeWeight
  }
}

export function createMarkerLabel({
  text = '',
  className = '',
  selected = false
}): google.maps.MarkerLabel {
  return {
    text: text ? text : '',
    className: `${className}${selected ? ' selected' : ''}`,
    color: GM_LABEL_FONT_COLOR,
    fontFamily: GM_LABEL_FONT_FAMILY,
    fontSize: GM_LABEL_FONT_SIZE,
    fontWeight: GM_LABEL_FONT_WEIGHT
  }
}

export const render: Renderer['render'] = ({ count, position }) => {
  const svg = window.btoa(`
  <svg fill="${GM_MARKER_COLOR}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".9" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
    <circle cx="120" cy="120" opacity=".1" r="130" />
  </svg>`)

  return new google.maps.Marker({
    icon: {
      url: `data:image/svg+xml;base64,${svg}`,
      scaledSize: new google.maps.Size(45, 45),
      size: new google.maps.Size(45, 45),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(26, 36)
    },
    label: createMarkerLabel({ text: String(count) }),
    position,
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
  })
}

export function mapPlacesServiceStatus(status?: google.maps.places.PlacesServiceStatus) {
  switch (status) {
    case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
      return 'This request was invalid.'
    case google.maps.places.PlacesServiceStatus.NOT_FOUND:
      return 'The place referenced was not found.'
    case google.maps.places.PlacesServiceStatus.OK:
      return 'The response contains a valid result.'
    case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
      return 'The application has gone over its request quota.'
    case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
      return 'The application is not allowed to use the PlacesService.'
    case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
      return 'The PlacesService request could not be processed due to a server error. The request may succeed if you try again.'
    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
      return 'No result was found for this request.'

    default:
      return 'Unknown status'
  }
}

export function mapGeocoderStatus(status?: google.maps.GeocoderStatus) {
  switch (status) {
    case google.maps.GeocoderStatus.ERROR:
      return 'There was a problem contacting the Google servers.'
    case google.maps.GeocoderStatus.INVALID_REQUEST:
      return 'This GeocoderRequest was invalid.'
    case google.maps.GeocoderStatus.OK:
      return 'The response contains a valid GeocoderResponse.'
    case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
      return 'The webpage has gone over the requests limit in too short a period of time.'
    case google.maps.GeocoderStatus.REQUEST_DENIED:
      return 'The webpage is not allowed to use the geocoder.'
    case google.maps.GeocoderStatus.UNKNOWN_ERROR:
      return 'A geocoding request could not be processed due to a server error. The request may succeed if you try again.'
    case google.maps.GeocoderStatus.ZERO_RESULTS:
      return 'No result was found for this GeocoderRequest.'

    default:
      return 'Unknown status'
  }
}

// This method avoids errors when fetching the lat and lng from a PlaceResult or similar.
//
// A PlaceResult can both be accessed directly after fetching or from the localStorage after it is cached.
// When it is cached in localStorage the lat() and lng() functions are converted to their fetched value
export function sanitizeLatLng(
  lat: google.maps.LatLng['lat'] | undefined,
  lng: google.maps.LatLng['lng'] | undefined
): google.maps.LatLngLiteral {
  try {
    return {
      lat: lat ? lat() : 0,
      lng: lng ? lng() : 0
    }
  } catch (error) {
    // console.warn(error)

    return {
      lat: lat as unknown as number,
      lng: lng as unknown as number
    }
  }
}

export function extractPlaceResultFields(place: any, fields: string[]): NNPlace {
  const extractedData: any = {}

  for (const field of fields) {
    if (field === 'geometry' && place[field]) {
      extractedData[field] = {
        location: sanitizeLatLng(place[field].location.lat, place[field].location.lng)
      }
    } else if (place[field]) {
      extractedData[field] = place[field]
    }
  }

  return extractedData as NNPlace
}
