<template>
  <div class="flex flex-column justify-content-center align-content-center relative h-30rem">
    <nn-map
      useSearch
      :options="nnMapOptions"
      :markers="markers"
      @click="mapClicked"
      @ready="map = $event"
    >
    </nn-map>
    <div
      v-if="places && places.length > 1"
      class="absolute w-full max-h-20rem bottom-0 px-3 overflow-hidden flex flex-column"
    >
      <pv-toolbar class="sticky top-0 z-2">
        <template #start> {{ $t('gmap.placeSelectTitle') }} </template>
        <template #center> </template>
        <template #end>
          <pv-button icon="pi pi-times" class="nn-button" @click="places.length = 0" />
        </template>
      </pv-toolbar>
      <div class="overflow-scroll">
        <pv-listbox
          v-model="selectedPlace"
          :options="places"
          optionLabel="name"
          class="w-full min-h-10rem"
          @click="listClicked"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createMarkerSymbol, sanitizeLatLng } from '@/google-maps/utils'
import type { MarkerLocation } from '@/models/locations'
import { GESTURE_HANDLING, type NNPlace } from '@/models/google-maps'
import { ref, watch, type Ref } from 'vue'
import { useAddressesStore } from '@/stores/addresses.store'

export interface Props {
  nnPlace: NNPlace
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'change', value: NNPlace): void
}>()

const map: Ref<google.maps.Map | undefined> = ref()
const markers: Ref<MarkerLocation[]> = ref([])
const selectedPlace: Ref<NNPlace | undefined> = ref()
const places: Ref<NNPlace[]> = ref([])
const addressesStore = useAddressesStore()

watch(map, async (googleMap) => {
  if (!googleMap) return

  if (props.nnPlace?.geometry?.location) {
    await updateMarker(props.nnPlace)
  }
})

const nnMapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  fullscreenControl: true,
  clickableIcons: true,
  gestureHandling: GESTURE_HANDLING.AUTO,
  rotateControl: false,
  streetViewControl: false,
  scaleControl: true
}

const mapClicked = async (nnPlace: NNPlace) => {
  if (!nnPlace.name && nnPlace.geometry?.location && map.value) {
    try {
      places.value = await addressesStore.fetchPlaces(nnPlace.geometry.location.toJSON(), map.value)
      if (!places.value || !places.value.length) {
        throw new Error('addressesStore.fetchPlaces no places returned')
      }
    } catch (error) {
      console.error(error)
    }
  }
  await updateMarker(nnPlace)
}

const listClicked = async () => {
  if (selectedPlace.value) {
    await updateMarker(selectedPlace.value)
    places.value.length = 0
  }
}

const updateMarker = async (nnPlace: NNPlace) => {
  if (!nnPlace.geometry?.location) {
    return
  }

  const latLng = sanitizeLatLng(nnPlace.geometry?.location?.lat, nnPlace.geometry?.location?.lng)

  const marker = createMarker(latLng.lat, latLng.lng)
  markers.value = [marker]

  emit('change', nnPlace)
}

function createMarker(lat: number, lng: number): MarkerLocation {
  return {
    id: '1',
    position: { lat, lng },
    optimized: false,
    icon: createMarkerSymbol({})
  }
}
</script>
