import type {
  SBImageSizeOptions,
  SBMarginOptions,
  SBPaddingOptions,
  SBTextAlignmentOptions
} from '@/models/storyblok'

export function itemAlignmetOptions(blok: SBTextAlignmentOptions) {
  return {
    'justify-content-start': blok.align === 'left',
    'justify-content-center': blok.align === 'center',
    'justify-content-end': blok.align === 'right'
  }
}

export function textAlignmetOptions(blok: SBTextAlignmentOptions) {
  return {
    'text-left': blok.align === 'left',
    'text-center': blok.align === 'center',
    'text-right': blok.align === 'right'
  }
}

export function verticalPaddingOptions(blok: SBPaddingOptions) {
  return {
    'py-sm': blok.verticalPadding === 'sm',
    'py-md': blok.verticalPadding === 'md',
    'py-lg': blok.verticalPadding === 'lg',
    'py-xl': blok.verticalPadding === 'xl'
  }
}

export function horizontalPaddingOptions(blok: SBPaddingOptions) {
  return {
    'px-sm': blok.horizontalPadding === 'sm',
    'px-md': blok.horizontalPadding === 'md',
    'px-lg': blok.horizontalPadding === 'lg',
    'px-xl': blok.horizontalPadding === 'xl'
  }
}

export function verticalMarginOptions(blok: SBMarginOptions) {
  return {
    'my-sm': blok.verticalMargin === 'sm',
    'my-md': blok.verticalMargin === 'md',
    'my-lg': blok.verticalMargin === 'lg',
    'my-xl': blok.verticalMargin === 'xl'
  }
}

export function horizontalMarginOptions(blok: SBMarginOptions) {
  return {
    'mx-sm': blok.horizontalMargin === 'sm',
    'mx-md': blok.horizontalMargin === 'md',
    'mx-lg': blok.horizontalMargin === 'lg',
    'mx-xl': blok.horizontalMargin === 'xl'
  }
}

export function imageSizeOptions(blok: SBImageSizeOptions) {
  return {
    sm: blok.imageSize === 'sm' || blok.imageSize === '-',
    md: blok.imageSize === 'md',
    lg: blok.imageSize === 'lg',
    xl: blok.imageSize === 'xl'
  }
}
