<template>
  <div class="header pb-4">
    <h2 class="title m-0 text-2xl text-center">
      <span class="capitalize">{{ $t('common.incident') }}</span> {{ $t('common.in') }}
      {{ formatDate(formState.incidentRequest.date) }}
    </h2>
  </div>
  <Incident v-if="formState.currentStep === 2" :incident="incident"></Incident>
  <div class="mb-3 p-3 nn-section bg-light-blue">
    <p class="nn-text text-sm font-bold pb-3">
      {{ $t('forms.incidentForm.messages.extraInfoTitle') }}
    </p>
    <p class="nn-text text-sm">{{ $t('forms.incidentForm.messages.extraInfoMessage') }}</p>
  </div>
  <NNFormQuestion
    :errorCondition="formTriggers.location && formState.getLocationError"
    :title="$t('forms.incidentForm.steps.v2.extra.description')"
  >
    <pv-textarea
      class="w-full"
      v-model="formState.incidentRequest.description"
      rows="10"
      maxlength="900"
      :autoResize="true"
      :placeholder="$t('forms.incidentForm.steps.v1.description.maxLengthMessage')"
      :ariaLabel="$t('forms.incidentForm.steps.v1.description.title')"
    />
    <div class="text-right">{{ formState.incidentRequest.description?.length }}/900</div>
  </NNFormQuestion>
</template>

<script setup lang="ts">
import NNFormQuestion from '../common/nn-form-question.vue'
import Incident from '@/components/storyblok/apps/nn-incident/nn-incident.incident.vue'
import { useIncidentFormStore } from '@/stores'
import dayjs from 'dayjs'
import { provide } from 'vue'
import { computed } from 'vue'

interface Props {
  formTriggers: any
}

defineProps<Props>()
provide('onlyAppearance', true)

const formState = useIncidentFormStore()
const incident = computed(() => {
  const { date, ...rest } = formState.incidentRequest
  return rest
})

const formatDate = (ISODate: string) => {
  if (!ISODate) return
  return dayjs(ISODate).format('YYYY')
}
</script>
