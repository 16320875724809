<template>
  <div v-if="isIncidentDto(incident)" :id="incident.id" class="flex flex-column nn-border my-4 p-2">
    <p class="color-blue m-0 mb-2 text-center">
      {{ incidentDateFormatted(incident.date || '') }}
    </p>
    <p class="color-blue m-0 mb-1">{{ incidentMethodFormatted(incident.method || []) }}</p>
    <p class="color-blue m-0 mb-1" v-if="incident.culprit">
      {{ $t('forms.incidentForm.summary.culprit') }}:
      {{ incidentPersonFormatted(incident.culprit) }}
    </p>
    <p class="color-blue m-0 mb-2" v-if="incident.target">
      {{ $t('forms.incidentForm.summary.target') }}:
      {{ incidentPersonFormatted(incident.target) }}
    </p>
    <pv-button
      class="button w-auto align-self-end"
      label="details"
      iconPos="right"
      icon="pi pi-angle-right"
      aria-label="incident details"
      size="small"
      link
      @click="goToIncidentSummary(incident.id)"
    ></pv-button>
  </div>

  <div v-if="!isIncidentDto(incident)" class="flex flex-column nn-border my-4 p-2">
    <div class="surface-card">
      <div class="flex justify-content-center mb-3">
        <PVSkeleton width="5rem" class="mb-2"></PVSkeleton>
      </div>
      <PVSkeleton width="100%" height="4rem"></PVSkeleton>
      <div class="flex justify-content-end mt-3">
        <PVSkeleton width="4rem" height="1.25rem"></PVSkeleton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IncidentType } from '@/stores/incident.store.types'
import {
  incidentDateFormatted,
  incidentMethodFormatted,
  incidentPersonFormatted,
  isIncidentDto
} from '@/stores/incident.store.fns'
import { onMounted, ref } from 'vue'
import { useIncidentStore } from '@/stores/incident.store'
import { goToIncidentSummary } from '@/utils/utils'

interface Props {
  incidentType: IncidentType
}

const props = defineProps<Props>()

const incidentStore = useIncidentStore()

const incident = ref()

onMounted(async () => {
  incident.value = props.incidentType
  await waitForIncident()
})

async function waitForIncident() {
  while (!isIncidentDto(incident.value)) {
    incident.value = await incidentStore.getIncident(incident.value.id)
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
}
</script>
