import type { App } from 'vue'

import nnMap from '@/components/nono/nn-map.vue'
import nnMapInput from '@/components/nono/nn-map-input.vue'
import nnIncidentCard from '@/components/nono/nn-incident-card.vue'
import nnLoading from '@/components/nono/nn-loading.vue'
import MultiSelect from '@/components/nono/MultiSelect.vue'
import ErrorMessage from '@/components/nono/ErrorMessage.vue'

export const defineComponents = (app: App) => {
  app.component('nn-incident-card', nnIncidentCard)
  app.component('nn-loading', nnLoading)
  app.component('nn-map-input', nnMapInput)
  app.component('nn-map', nnMap)
  app.component('NNMultiSelect', MultiSelect)
  app.component('ErrorMessage', ErrorMessage)
}
