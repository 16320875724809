<template v-if="story?.content">
  <pv-toolbar v-editable="story.content" class="nn-header">
    <template #start>
      <p class="nn-text heading color-blue" @click="goHome()">{{ story.content.title }}</p>
    </template>
    <template #end>
      <pv-Badge
        v-if="auth.authenticated"
        :value="story?.content?.signedInText"
        severity="info"
        class="nn-badge mr-3"
      />
      <pv-button
        class="nn-menu-button"
        aria-label="Menu button"
        icon="pi pi-bars"
        size="large"
        rounded
        link
        @click="menu.isVisible = true"
      />
    </template>
  </pv-toolbar>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores'
import { getStory, getStoryLanguage } from '@/utils'
import { useMenuStore } from '@/stores'
import { getLanguage } from '@/utils/language'

const auth = useAuthStore()
const menu = useMenuStore()

const language = getStoryLanguage()
const story = await getStory('headers/main-header', language)

const goHome = () => {
  window.open(`/${getLanguage()}`, '_top')
}
</script>
