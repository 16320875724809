<template>
  <div class="grid mx-3">
    <div class="col flex justify-content-center">
      <pv-button
        label="English"
        :class="{
          underline: currentLanguage === Language.ENGLISH
        }"
        link
        text
        @click="changeLanguage(Language.ENGLISH)"
      />
    </div>
    <div class="col flex justify-content-center align-items-center color-white">/</div>
    <div class="col flex justify-content-center">
      <pv-button
        label="Nederlands"
        :class="{
          underline: currentLanguage === Language.DUTCH
        }"
        link
        text
        @click="changeLanguage(Language.DUTCH)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useMenuStore } from '@/stores'
import { getLanguage } from '@/utils/language'
import { Language } from '@/models/languages'

const menu = useMenuStore()

const currentLanguage = ref(getLanguage())

const changeLanguage = (language: Language) => {
  menu.isVisible = false
  const url = new URL(window.location.href)
  const paths = url.pathname.split('/')
  paths[1] = language
  const newPath = paths.join('/')
  window.open(newPath, '_top')
}
</script>
