import { acceptHMRUpdate, defineStore } from 'pinia'
import { ADDRESSES_STORE_ID } from './constants'
import type { AddressesState } from './addresses.store.types'
import { getLanguage } from '@/utils/language'
import {
  extractPlaceResultFields,
  mapGeocoderStatus,
  mapPlacesServiceStatus
} from '@/google-maps/utils'
import type { NNPlace, PlaceID } from '@/models/google-maps'
import { PLACE_EXTENDED_FIELDS, PLACE_REQUIRED_FIELDS } from '@/google-maps/constants'

export const useAddressesStore = defineStore({
  id: ADDRESSES_STORE_ID,
  state: (): AddressesState => ({ ...getDefaultState() }),
  getters: {
    getPlaceDetails: (state) => (placeId: string) => {
      return state.places[placeId] || null
    }
  },
  actions: {
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    },
    setPlaceDetails(
      placeId: PlaceID,
      place: google.maps.places.PlaceResult | google.maps.GeocoderResult | NNPlace
    ) {
      if (!placeId) return
      const hasAllRequiredFields = PLACE_REQUIRED_FIELDS.every(
        (key) => place[key as keyof typeof place]
      )
      if (!hasAllRequiredFields) return
      this.places[placeId] = extractPlaceResultFields(place, [
        ...PLACE_REQUIRED_FIELDS,
        ...PLACE_EXTENDED_FIELDS
      ])
    },
    async fetchPlaceDetails(placeId: PlaceID, map: google.maps.Map): Promise<NNPlace> {
      if (!placeId) {
        return new Promise((_, reject) => {
          reject(new Error('placeId is null or undefined'))
        })
      }

      const placeDetails = this.getPlaceDetails(placeId)
      if (placeDetails) return placeDetails

      const service = new google.maps.places.PlacesService(map)

      const request: google.maps.places.PlaceDetailsRequest = {
        placeId,
        fields: [...PLACE_REQUIRED_FIELDS, ...PLACE_EXTENDED_FIELDS],
        language: getLanguage()
      }

      return new Promise((resolve, reject) => {
        service.getDetails(request, (result, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(new Error(mapPlacesServiceStatus(status)))
            return
          }
          if (result === null) {
            reject(new Error('Result is null'))
            return
          }

          this.setPlaceDetails(placeId, result)

          resolve(
            extractPlaceResultFields(result, [...PLACE_REQUIRED_FIELDS, ...PLACE_EXTENDED_FIELDS])
          )
        })
      })
    },
    async fetchGeocode(location: google.maps.LatLngLiteral): Promise<NNPlace> {
      const service = new google.maps.Geocoder()

      const request: google.maps.GeocoderRequest = { location }

      return new Promise((resolve, reject) => {
        service.geocode(request, (result, status) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            reject(new Error(mapGeocoderStatus(status)))
            return
          }
          if (result === null) {
            reject(new Error('Result is null'))
            return
          }
          if (!result.length) {
            reject(new Error('Result is empty'))
            return
          }

          this.setPlaceDetails(result[0].place_id, result[0])
          const nnPlace = extractPlaceResultFields(result[0], [
            ...PLACE_REQUIRED_FIELDS,
            ...PLACE_EXTENDED_FIELDS
          ])
          resolve(nnPlace)
        })
      })
    },
    async fetchPlaces(
      location: google.maps.LatLngLiteral,
      map: google.maps.Map
    ): Promise<NNPlace[]> {
      const service = new google.maps.places.PlacesService(map)

      const request: google.maps.places.PlaceSearchRequest = {
        location,
        bounds: map.getBounds(),
        rankBy: google.maps.places.RankBy.PROMINENCE, // or `PROMINENCE`
        language: getLanguage()
      }

      const self = this

      return new Promise((resolve, reject) => {
        service.nearbySearch(request, function (results, status, pagination) {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(new Error(mapPlacesServiceStatus(status)))
            return
          }
          if (results === null) {
            reject(new Error('Results is null'))
            return
          }
          if (!results.length) {
            reject(new Error('Results is empty'))
            return
          }

          // console.log('pagination:', pagination?.hasNextPage)

          const placesPromises: Promise<NNPlace>[] = []
          for (const place of results) {
            const nnPlace = extractPlaceResultFields(place, [
              ...PLACE_REQUIRED_FIELDS,
              ...PLACE_EXTENDED_FIELDS
            ])
            if (nnPlace.place_id) {
              // save the places
              placesPromises.push(self.fetchPlaceDetails(nnPlace.place_id, map))
            }
          }

          resolve(Promise.all(placesPromises))
        })
      })
    }
  },
  persist: {
    storage: localStorage
  }
})

function getDefaultState(): AddressesState {
  return {
    places: {}
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAddressesStore, import.meta.hot))
}
