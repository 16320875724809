import type { App } from 'vue'

// Apps
import nnEmailForm from '@/components/storyblok/apps/nn-email-form.vue'
import nnLoginForm from '@/components/storyblok/apps/nn-login-form.vue'
import nnIncident from '@/components/storyblok/apps/nn-incident/nn-incident.vue'
import nnIncidentForm from '@/components/storyblok/apps/nn-incident-form/nn-incident-form.vue'
import nnIncidentFormV2 from '@/components/storyblok/apps/nn-incident-form/v2/nn-incident-form-v2.vue'
import nnIncidentMap from '@/components/storyblok/apps/nn-incident-map.vue'

// Components
import nnButton from '@/components/storyblok/components/nn-button.vue'
import nnButtonBar from '@/components/storyblok/components/nn-button-bar.vue'
import nnDivider from '@/components/storyblok/components/nn-divider.vue'
import nnImage from '@/components/storyblok/components/nn-image.vue'
import nnLink from '@/components/storyblok/components/nn-link.vue'
import nnSection from '@/components/storyblok/components/nn-section.vue'
import nnText from '@/components/storyblok/components/nn-text.vue'
import nnTextarea from '@/components/storyblok/components/nn-textarea.vue'

// Content types
import nnPage from '@/components/storyblok/content_types/nn-page.vue'
import nnMenu from '@/components/storyblok/content_types/nn-menu.vue'
import nnHeader from '@/components/storyblok/content_types/nn-header.vue'
import nnFooter from '@/components/storyblok/content_types/nn-footer.vue'

// Defining components here will allow global use with the `StoryblokComponent` component
export const defineStoryblokComponents = (app: App) => {
  // apps
  app.component('email_form', nnEmailForm)
  app.component('login_form', nnLoginForm)
  app.component('nn-incident', nnIncident)
  app.component('incident_form', nnIncidentForm)
  app.component('nn-incident-form-v2', nnIncidentFormV2)
  app.component('nn-incident-map', nnIncidentMap)

  // components
  app.component('nn-button', nnButton)
  app.component('nn-button-bar', nnButtonBar)
  app.component('nn-divider', nnDivider)
  app.component('nn-image', nnImage)
  app.component('nn-link', nnLink)
  app.component('nn-section', nnSection)
  app.component('nn-text', nnText)
  app.component('nn-textarea', nnTextarea)

  // content types
  app.component('nn-footer', nnFooter)
  app.component('nn-header', nnHeader)
  app.component('nn-menu', nnMenu)
  app.component('nn-page', nnPage)
}
