import { acceptHMRUpdate, defineStore } from 'pinia'
import { MENU_STORE_ID } from './constants'

export const useMenuStore = defineStore({
  id: MENU_STORE_ID,
  state: () => ({ ...getDefaultState() }),
  actions: {
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot))
}

function getDefaultState() {
  return {
    isVisible: false
  }
}
