<template>
  <div
    class="nn-map flex flex-column justify-content-center align-content-center w-full h-full relative"
  >
    <GMapMap
      :disableDefaultUI="true"
      :mapTypeId="mapTypeId"
      :options="options"
      :zoom="zoom"
      :center="center"
      :maxZoom="0"
      :minZoom="1"
      ref="mapRef"
      class="w-full h-full"
      @click="mapClicked"
      @zoom_changed="mapStore.zoom = $event"
    >
      <template v-if="mapReady">
        <div
          v-if="useSearch"
          class="fadein animation-duration-1000 animation-ease-in-out absolute left-0 top-0 p-2 w-10"
          :class="{
            'pt-7': options.mapTypeControl
          }"
        >
          <span class="p-input-icon-right">
            <i
              v-if="autocomplete.length > 0"
              class="pi pi-times cursor-pointer"
              @click="inputCloseClicked"
            ></i>
            <i v-else class="pi pi-search"></i>
            <GMapAutocomplete
              class="p-inputtext p-component w-full capitalize"
              id="gm-autocomplete"
              :placeholder="$t('common.search')"
              :options="autocompleteOptions"
              @place_changed="placeChanged"
            ></GMapAutocomplete>
          </span>
        </div>
        <GMapCluster
          v-if="markers?.length"
          :minimumClusterSize="2"
          :zoomOnClick="true"
          :renderer="{ render }"
        >
          <GMapMarker
            v-for="marker in markers"
            :key="marker.id"
            :position="marker.position"
            :clickable="markerOptions.clickable"
            :draggable="markerOptions.draggable"
            :icon="openedMarkerID === marker.id ? iconSelected : marker.icon"
            :label="marker.label"
            @click="openMarker(marker)"
            optimized
          >
            <GMapInfoWindow
              v-if="useInfoWindow"
              :closeclick="true"
              @closeclick=";(openedMarkerID = null), (openedMarker = null)"
              :opened="openedMarkerID === marker.id"
            >
              <slot name="info-window"></slot>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapCluster>
        <slot name="dialog"></slot>
      </template>
    </GMapMap>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import type { MarkerLocation } from '@/models/locations'
import type { UUID } from '@/stores/incident.store.types'
import { MAP_TYPE, ZOOM_LEVEL, type NNPlace } from '@/models/google-maps'
import { GESTURE_HANDLING } from '@/models/google-maps'
import {
  createMarkerSymbol,
  extractPlaceResultFields,
  render,
  sanitizeLatLng
} from '@/google-maps/utils'
import { onMounted } from 'vue'
import type { Ref } from 'vue'
import { useMapStore } from '@/stores/map.store'
import { color } from '@/utils/constants'
import {
  GM_SELECTED_MARKER_ANCHOR,
  GM_SELECTED_MARKER_LABELORIGIN,
  GM_SELECTED_MARKER_PATH,
  GM_SELECTED_MARKER_SCALE,
  PLACE_EXTENDED_FIELDS,
  PLACE_REQUIRED_FIELDS
} from '@/google-maps/constants'
import { useAddressesStore } from '@/stores/addresses.store'
import { getLanguage } from '@/utils/language'

interface Props {
  mapTypeId?: MAP_TYPE
  useInfoWindow?: boolean
  useSearch?: boolean
  options: google.maps.MapOptions
  markerOptions: {
    clickable: true
    draggable: false
  }
  markers: MarkerLocation[]
}

const props = withDefaults(defineProps<Props>(), {
  mapTypeId: MAP_TYPE.TERRAIN,
  options() {
    return {
      zoomControl: true,
      mapTypeControl: true,
      fullscreenControl: true,
      clickableIcons: true,
      gestureHandling: GESTURE_HANDLING.AUTO,
      rotateControl: false,
      streetViewControl: false,
      scaleControl: true
    }
  },
  markerOptions() {
    return {
      clickable: true,
      draggable: false
    }
  }
})

const currentInfoWindow: any = ref(null)

const mapStore = useMapStore()

const emit = defineEmits<{
  (e: 'markerClick', value: MarkerLocation): void
  (e: 'click', value: NNPlace): void
  (e: 'ready', value: google.maps.Map): void
}>()

const addressesStore = useAddressesStore()

const map: Ref<google.maps.Map | undefined> = ref()
const mapRef = ref()
const mapReady: Ref<boolean> = ref(false)
const iconSelected: Ref<google.maps.Symbol | undefined> = ref()
const center: Ref<google.maps.LatLngLiteral> = ref(mapStore.center)
const zoom: Ref<number> = ref(mapStore.zoom)
const openedMarkerID: Ref<UUID | null> = ref(null)
const openedMarker: Ref<MarkerLocation | null> = ref(null)
const autocomplete = ref('')

const autocompleteOptions = {
  language: getLanguage(),
  strictBounds: false
}

const openMarker = (marker: MarkerLocation) => {
  if (props?.options?.gestureHandling === GESTURE_HANDLING.NONE) {
    return
  }

  emit('markerClick', marker)

  center.value = marker.position
  mapStore.center = marker.position

  if (!marker.id) {
    return
  }

  openedMarkerID.value = marker.id
  openedMarker.value = marker
}

const centerChanged = (nnPlace: NNPlace) => {
  const latLng = sanitizeLatLng(nnPlace.geometry?.location?.lat, nnPlace.geometry?.location?.lng)

  openedMarkerID.value = null
  openedMarker.value = null

  if (latLng.lat && latLng.lng) {
    center.value = latLng
    mapStore.center = latLng
  }
  emit('click', nnPlace)
}

const placeChanged = async (nnPlace: NNPlace) => {
  if (props?.options?.gestureHandling === GESTURE_HANDLING.NONE) {
    return
  }

  if (!nnPlace) {
    return
  }
  if (nnPlace.place_id && nnPlace.geometry?.location) {
    addressesStore.setPlaceDetails(nnPlace.place_id, nnPlace)
    const newNNPlace = extractPlaceResultFields(nnPlace, [
      ...PLACE_REQUIRED_FIELDS,
      ...PLACE_EXTENDED_FIELDS
    ])
    centerChanged(newNNPlace)
  } else if (nnPlace.geometry?.location) {
    const latLng = sanitizeLatLng(nnPlace.geometry?.location.lat, nnPlace.geometry?.location.lng)
    const event = createMapMouseEvent(latLng)
    await handleAsMapMouseEvent(event)
  }

  zoom.value = ZOOM_LEVEL.STREETS + 2
  mapStore.zoom = ZOOM_LEVEL.STREETS + 2
}

const handleAsMapMouseEvent = async (event: google.maps.MapMouseEvent) => {
  if (!event?.latLng || !map.value) return

  const latLng = sanitizeLatLng(event.latLng.lat, event.latLng.lng)

  const geocode = await fetchFromGeocode(latLng)

  const nnPlace: NNPlace = {
    formatted_address: geocode?.formatted_address || '',
    geometry: {
      location: event.latLng
    },
    name: geocode?.name,
    place_id: geocode?.place_id
  }

  centerChanged(nnPlace)
}

// If the map clicked event has a placeId we can immediately fetch place data
const handleAsIconMouseEvent = async (event: google.maps.IconMouseEvent) => {
  if (!event.placeId || !map.value) {
    return
  }

  event.stop()

  try {
    const nnPlace = await addressesStore.fetchPlaceDetails(event.placeId, map.value)
    if (!nnPlace.geometry?.location) {
      throw new Error('nnPlace.geometry.location is undefined')
    }

    centerChanged(nnPlace)
  } catch (error) {
    console.error(error)
    handleAsMapMouseEvent(event)
  }
}

const interceptInfoWindow = (event: google.maps.IconMouseEvent) => {
  // Listener to handle clicks on POIs
  if (event.placeId) {
    // Prevent the default InfoWindow from opening
    event.stop()

    // If an InfoWindow is already open, close it
    if (currentInfoWindow.value) {
      currentInfoWindow.value.close()
    }

    // Create a new InfoWindow (if you want to open one)
    const infoWindow = new google.maps.InfoWindow({
      // content: 'Custom content for POI: ' + event.placeId,
      content: event.placeId
    })

    // Open the new InfoWindow at the clicked location
    infoWindow.setPosition(event.latLng)
    infoWindow.open(map.value)

    // Update the reference to the currently open InfoWindow
    currentInfoWindow.value = infoWindow
  }
}

const mapClicked = async (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
  // TODO: better infowindow usage
  // interceptInfoWindow(event as google.maps.IconMouseEvent)

  if (props?.options?.gestureHandling === GESTURE_HANDLING.NONE) {
    return
  }

  openedMarkerID.value = null

  if ((event as google.maps.IconMouseEvent).placeId) {
    await handleAsIconMouseEvent(event as google.maps.IconMouseEvent)
  } else {
    await handleAsMapMouseEvent(event)
  }
}

// when single marker is loaded center it and open the info-window
const singleMarkerLoaded = () => {
  if (props.markers?.length !== 1) return
  center.value = { lat: props.markers[0].position.lat, lng: props.markers[0].position.lng }
  openedMarkerID.value = props.markers[0].id as string
}

const inputCloseClicked = () => {
  const input = document.getElementById('gm-autocomplete') as HTMLInputElement
  if (!input) return
  input.value = ''
  autocomplete.value = ''
}

const setSelectedIconValue = () => {
  if (!map.value) return

  if (props.markers?.length > 1) {
    iconSelected.value = createMarkerSymbol({
      fillColor: color.red,
      strokeColor: color.red,
      path: GM_SELECTED_MARKER_PATH,
      anchor: GM_SELECTED_MARKER_ANCHOR(),
      scale: GM_SELECTED_MARKER_SCALE,
      labelOrigin: GM_SELECTED_MARKER_LABELORIGIN()
    })
  } else {
    iconSelected.value = createMarkerSymbol({})
  }
}

watch(mapRef, async (googleMap) => {
  if (googleMap === null) return
  map.value = await googleMap.$mapPromise
  if (!map.value) return

  setSelectedIconValue()

  mapReady.value = true

  emit('ready', map.value)
})

watch(mapReady, async () => {
  if (!props.useSearch) {
    return
  }

  setTimeout(() => {
    const input = document.getElementById('gm-autocomplete') as HTMLInputElement
    if (!input) return
    input.addEventListener('input', () => {
      autocomplete.value = input.value
    })
  }, 0)
})

watch(props, async () => {
  singleMarkerLoaded()
  setSelectedIconValue()
})

onMounted(async () => {
  singleMarkerLoaded()

  if (mapStore.lastUserLocation) return
  const result = await mapStore.getUserLocation()
  if (!result) return
  center.value = result
})

// After Geocoding the lat and lng we get can be different from what the PlaceDetails API would reply with for the same place.
// So at the moment also fetching the place details and using that detail whenever possible
async function fetchFromGeocode(location: google.maps.LatLngLiteral): Promise<NNPlace | undefined> {
  try {
    return await addressesStore.fetchGeocode(location)
  } catch (error) {
    console.error(error)
  }
}

function createMapMouseEvent({ lat, lng }: google.maps.LatLngLiteral) {
  return {
    domEvent: new Event('CustomMapMouseEvent'),
    stop: () => {},
    latLng: new google.maps.LatLng({
      lat,
      lng
    })
  }
}
</script>
