<template v-if="story?.content">
  <pv-sidebar
    v-model:visible="menu.isVisible"
    position="full"
    :baseZIndex="10000"
    ariaCloseLabel="Close Menu"
    class="nn-menu"
  >
    <div class="pt-2" v-editable="story.content">
      <p
        v-if="story.content.showTitle && story.content.title"
        v-html="renderRichText(story.content.title)"
      ></p>
      <nav class="pb-8">
        <div v-for="(link, index) in story.content.links" :key="link._uid">
          <nn-link :blok="link" />
          <pv-divider
            v-if="index !== story.content.links.length - 1"
            type="solid"
            layout="horizontal"
            align="center"
          />
        </div>
      </nav>
      <div class="flex justify-content-center align-content-center">
        <LanguageButton />
      </div>
    </div>
  </pv-sidebar>
</template>

<script setup lang="ts">
import LanguageButton from '@/components/nono/language-buttons.vue'

import { renderRichText } from '@storyblok/vue'
import { getStory, getStoryLanguage } from '@/utils'
import { useMenuStore } from '@/stores'

const menu = useMenuStore()

const language = getStoryLanguage()
const story = await getStory('menus/main-menu', language)
</script>
