import type { App } from 'vue'
import VueGoogleMaps from 'vue-google-maps-community-fork'

export const defineGoogleMaps = (app: App) => {
  app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      libraries: 'places,geocoding'
    },
    autobindAllEvents: false
  })
}
