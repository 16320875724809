export const GESTURE_HANDLING = {
  COOPERATIVE: 'cooperative',
  GREEDY: 'greedy',
  NONE: 'none',
  AUTO: 'auto'
} as const
export type GESTURE_HANDLING = (typeof GESTURE_HANDLING)[keyof typeof GESTURE_HANDLING]

export const MAP_TYPE = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  HYBRID: 'hybrid',
  TERRAIN: 'terrain'
} as const
export type MAP_TYPE = (typeof MAP_TYPE)[keyof typeof MAP_TYPE]

export type PlaceID = string | null

export const ZOOM_LEVEL = {
  WORLD: 1,
  LANDMASS_CONTINENT: 5,
  CITY: 10,
  STREETS: 15,
  BUILDINGS: 20
} as const
export type ZOOM_LEVEL = (typeof ZOOM_LEVEL)[keyof typeof ZOOM_LEVEL]

export interface NNPlace extends google.maps.places.PlaceResult {}
