import type { NNPlace } from '@/models/google-maps'
import { color } from '@/utils/constants'

export const GM_LABEL_FONT_FAMILY = 'Poppins'
export const GM_LABEL_FONT_SIZE = '12px'
export const GM_LABEL_FONT_WEIGHT = '400'
export const GM_LABEL_FONT_COLOR = 'rgba(255,255,255,0.9)'

export const GM_MARKER_COLOR = color.red50

// Default Marker
// widthxheight 20x32
export const GM_DEFAULT_MARKER_PATH =
  'M10 0c-5.531 0-10 4.469-10 10s5 13 10 22c5-9 10-16.469 10-22s-4.469-10-10-10zM10 14c-2.219 0-4-1.781-4-4s1.781-4 4-4 4 1.781 4 4-1.781 4-4 4z'
export const GM_DEFAULT_MARKER_ANCHOR = () => new google.maps.Point(10, 31)
export const GM_DEFAULT_MARKER_LABELORIGIN = () => new google.maps.Point(10, 10)
export const GM_DEFAULT_MARKER_SCALE = 1

// Selected Marker
// widthxheight 180x288
export const GM_SELECTED_MARKER_PATH =
  'M74 .7C55 4 32.7 16.9 20.6 31.6 6.5 48.8 0 67.2 0 90c0 14.1 1.7 23.7 6.9 39 10.1 30.2 18.4 46.3 71 139 6.1 10.7 11.5 19.5 12.1 19.5 1.6 0 47.5-81.4 61.5-109 21.8-43 27.4-60.3 28.2-86 .4-13.7.2-15.9-2.1-24.9-8.7-33.6-35.2-59.1-68.8-66.1C102.3.1 80.3-.4 74 .7z'
export const GM_SELECTED_MARKER_ANCHOR = () => new google.maps.Point(88, 280)
export const GM_SELECTED_MARKER_LABELORIGIN = () => new google.maps.Point(88, 90)
export const GM_SELECTED_MARKER_SCALE = 0.11

export const PLACE_REQUIRED_FIELDS: (keyof NNPlace)[] = ['formatted_address', 'geometry']
export const PLACE_EXTENDED_FIELDS: (keyof NNPlace)[] = ['name', 'place_id', 'icon', 'photos']
