import type { App } from 'vue'

import Sidebar from 'primevue/sidebar'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import Toolbar from 'primevue/toolbar'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Paginator from 'primevue/paginator'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Calendar from 'primevue/calendar'
import ToggleButton from 'primevue/togglebutton'
import ProgressBar from 'primevue/progressbar'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton'
import Image from 'primevue/image'
import ScrollTop from 'primevue/scrolltop'
import Inplace from 'primevue/inplace'
import Dialog from 'primevue/dialog'
import Listbox from 'primevue/listbox'

export function definePrimevueComponents(app: App) {
  app.component('pv-sidebar', Sidebar)
  app.component('pv-button', Button)
  app.component('pv-divider', Divider)
  app.component('pv-toolbar', Toolbar)
  app.component('InputText', InputText)
  app.component('pv-textarea', Textarea)
  app.component('pv-paginator', Paginator)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('pv-calendar', Calendar)
  app.component('ToggleButton', ToggleButton)
  app.component('ProgressBar', ProgressBar)
  app.component('pv-Badge', Badge)
  app.component('PVChip', Chip)
  app.component('PVSkeleton', Skeleton)
  app.component('pv-image', Image)
  app.component('pv-scroll-top', ScrollTop)
  app.component('pv-inplace', Inplace)
  app.component('pv-dialog', Dialog)
  app.component('pv-listbox', Listbox)

  app.directive('badge', BadgeDirective)
}
