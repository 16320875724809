import type { UUID } from '@/stores/incident.store.types'
import { getLanguage } from './language'

export function hexColorToRGB(hexColor: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hexColor = hexColor.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export function createBasicAuthorizationHeader(username: string, password: string) {
  const credentials = window.btoa(`${username}:${password}`)
  return {
    Authorization: `Basic ${credentials}`
  }
}

export function scrollToTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

export function goToIncidentSummary(id: UUID) {
  const baseUrl = `/${getLanguage()}/incidents`
  const queryParams: Record<string, string> = { incident: id }
  const queryString = Object.keys(queryParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&')
  const url = `${baseUrl}?${queryString}`
  window.open(url, '_top')
}
