import { acceptHMRUpdate, defineStore } from 'pinia'
import { MAP_STORE_ID } from './constants'
import type { MapStoreState } from './map.store.types'
import { DEFAULT_LOCATION, getUserLocation } from '@/utils'
import { ZOOM_LEVEL } from '@/models/google-maps'

export const useMapStore = defineStore({
  id: MAP_STORE_ID,
  state: (): MapStoreState => ({ ...getDefaultState() }),
  actions: {
    async getUserLocation() {
      try {
        this.lastUserLocation = await getUserLocation()
        return this.lastUserLocation
      } catch (error) {
        console.error(error)
      }
    },
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
}

function getDefaultState(): MapStoreState {
  return {
    zoom: ZOOM_LEVEL.CITY,
    center: DEFAULT_LOCATION,
    lastUserLocation: null
  }
}
