<template>
  <div v-editable="blok" class="flex flex-column">
    <div class="card">
      <ProgressBar class="mx-3 mt-3" :value="getProgress()" :showValue="false"></ProgressBar>

      <TabView ref="IncidentForm" v-model:activeIndex="formState.currentStep">
        <!-- 1 -->
        <TabPanel header="{{ $t('forms.incidentForm.steps.v1.whatAndWhen.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ formState.currentStep + 1 }}.
              {{ $t('forms.incidentForm.steps.v1.whatAndWhen.title') }}
            </h2>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.method && formState.getWhatHappenedError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.whatAndWhen.whatHappened') }}</p>
            <NNMultiSelect
              :blok="formState.methods"
              :single="false"
              :translate="true"
              @change="methodChanged"
            ></NNMultiSelect>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.date && formState.getDateError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.whatAndWhen.when') }}</p>
            <pv-calendar
              v-if="isMounted"
              id="date"
              class="w-full"
              selectionMode="single"
              dateFormat="MM dd, yy"
              hourFormat="24"
              :showTime="false"
              :minDate="minDate"
              :maxDate="maxDate"
              v-model="calendarDate"
              @dateSelect="dateChanged"
            />
          </div>
          <ErrorMessage
            :message="$t('forms.incidentForm.messages.requiredMessage')"
            v-show="
              (formTriggers.method && formState.getWhatHappenedError) ||
              (formTriggers.date && formState.getDateError)
            "
          >
          </ErrorMessage>
        </TabPanel>

        <!-- 2 -->
        <TabPanel header="{{ $t('forms.incidentForm.steps.v1.culprit.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ formState.currentStep + 1 }}. {{ $t('forms.incidentForm.steps.v1.culprit.title') }}
            </h2>
          </div>
          <div class="border-1 alert-container p-2 mb-4">
            <p class="m-0">{{ $t('forms.incidentForm.steps.v1.culprit.description') }}</p>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{
              error: formTriggers.culprit.isIndividual && formState.getCulpritCollectiveError
            }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.culprit.collective') }}</p>
            <NNMultiSelect
              :blok="formState.culprit.isIndividual"
              :single="true"
              :translate="true"
              @change="culpritCollectiveChanged"
            >
            </NNMultiSelect>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{
              error: formTriggers.culprit.appearance && formState.getCulpritAppearanceError
            }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.culprit.appearance') }}</p>
            <NNMultiSelect
              :blok="formState.culprit.appearance"
              :single="formState.incidentRequest.culprit.isIndividual"
              :translate="true"
              @change="culpritAppearanceChanged"
            ></NNMultiSelect>
            <p class="p-0 px-3 m-0 text-xs">
              <span class="vertical-align-middle">
                <i class="pi pi-info-circle"></i>
                {{ $t('forms.incidentForm.messages.indigenousDescription') }}
              </span>
            </p>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.culprit.gender && formState.getCulpritGenderError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.culprit.gender') }}</p>
            <NNMultiSelect
              :blok="formState.culprit.gender"
              :single="formState.incidentRequest.culprit.isIndividual"
              :translate="true"
              @change="culpritGenderChanged"
            ></NNMultiSelect>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.culprit.ageGroup && formState.getCulpritAgeGroupError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.culprit.ageGroup') }}</p>
            <NNMultiSelect
              :blok="formState.culprit.ageGroup"
              :single="formState.incidentRequest.culprit.isIndividual"
              :translate="true"
              @change="culpritAgeGroupChanged"
            ></NNMultiSelect>
          </div>

          <ErrorMessage
            :message="$t('forms.incidentForm.messages.requiredMessage')"
            v-show="
              (formTriggers.culprit.isIndividual && formState.getCulpritCollectiveError) ||
              (formTriggers.culprit.appearance && formState.getCulpritAppearanceError) ||
              (formTriggers.culprit.gender && formState.getCulpritGenderError) ||
              (formTriggers.culprit.ageGroup && formState.getCulpritAgeGroupError)
            "
          >
          </ErrorMessage>
        </TabPanel>

        <!-- 3 -->
        <TabPanel header="{{ $t('forms.incidentForm.steps.v1.target.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ formState.currentStep + 1 }}. {{ $t('forms.incidentForm.steps.v1.target.title') }}
            </h2>
          </div>
          <div class="border-1 alert-container p-2 mb-4">
            <p class="m-0">
              {{ $t('forms.incidentForm.steps.v1.target.description') }}
            </p>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{
              error: formTriggers.target.isIndividual && formState.getTargetCollectiveError
            }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.target.collective') }}</p>
            <NNMultiSelect
              :blok="formState.target.isIndividual"
              :single="true"
              :translate="true"
              @change="targetCollectiveChanged"
            >
            </NNMultiSelect>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.target.appearance && formState.getTargetAppearanceError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.target.appearance') }}</p>
            <NNMultiSelect
              :blok="formState.target.appearance"
              :single="formState.incidentRequest.target.isIndividual"
              :translate="true"
              @change="targetAppearanceChanged"
            ></NNMultiSelect>
            <p class="p-0 px-3 m-0 text-xs">
              <span class="vertical-align-middle">
                <i class="pi pi-info-circle"></i>
                {{ $t('forms.incidentForm.messages.indigenousDescription') }}
              </span>
            </p>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.target.gender && formState.getTargetGenderError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.target.gender') }}</p>
            <NNMultiSelect
              :blok="formState.target.gender"
              :single="formState.incidentRequest.target.isIndividual"
              :translate="true"
              @change="targetGenderChanged"
            ></NNMultiSelect>
          </div>
          <div
            class="question mb-3 pb-3 px-3"
            :class="{ error: formTriggers.target.ageGroup && formState.getTargetAgeGroupError }"
          >
            <p>{{ $t('forms.incidentForm.steps.v1.target.ageGroup') }}</p>
            <NNMultiSelect
              :blok="formState.target.ageGroup"
              :single="formState.incidentRequest.target.isIndividual"
              :translate="true"
              @change="targetAgeGroupChanged"
            ></NNMultiSelect>
          </div>

          <ErrorMessage
            :message="$t('forms.incidentForm.messages.requiredMessage')"
            v-show="
              (formTriggers.target.isIndividual && formState.getTargetCollectiveError) ||
              (formTriggers.target.appearance && formState.getTargetAppearanceError) ||
              (formTriggers.target.gender && formState.getTargetGenderError) ||
              (formTriggers.target.ageGroup && formState.getTargetAgeGroupError)
            "
          >
          </ErrorMessage>
        </TabPanel>

        <!-- 4 -->
        <TabPanel header="{{ $t('forms.incidentForm.steps.v1.where.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ formState.currentStep + 1 }}. {{ $t('forms.incidentForm.steps.v1.where.title') }}
            </h2>
          </div>

          <div
            class="question mb-3 p-3 -mx-3"
            :class="{ error: formTriggers.location && formState.getLocationError }"
          >
            <p class="">
              {{ $t('forms.incidentForm.steps.v1.where.description') }}
            </p>
            <nn-map-input :nnPlace="nnPlace" @change="mapChanged"></nn-map-input>
          </div>
          <div v-if="formState.address" class="pb-6">
            <p class="m-0 underline">
              {{ $t('forms.incidentForm.steps.v1.where.selectedLocation') }}
            </p>
            <p v-if="formState.placeName" class="m-0 blue">{{ formState.placeName }}</p>
            <p class="m-0 blue">{{ formState.address }}</p>
          </div>

          <ErrorMessage
            :message="$t('forms.incidentForm.messages.requiredMessage')"
            v-if="formTriggers.location && formState.getLocationError"
          ></ErrorMessage>
        </TabPanel>

        <!-- 5 -->
        <TabPanel header="{{ $t('forms.incidentForm.steps.v1.description.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ formState.currentStep + 1 }}.
              {{ $t('forms.incidentForm.steps.v1.description.title') }}
            </h2>
          </div>

          <div
            class="question mb-3 pb-3 px-3"
            :class="{
              error: formTriggers.description && formState.getDescriptionError
            }"
          >
            <p>
              {{ $t('forms.incidentForm.steps.v1.description.description') }}
            </p>
            <pv-textarea
              class="w-full"
              v-model="formState.incidentRequest.description"
              rows="10"
              maxlength="900"
              :autoResize="true"
              :placeholder="$t('forms.incidentForm.steps.v1.description.maxLengthMessage')"
              :ariaLabel="$t('forms.incidentForm.steps.v1.description.title')"
            />
            <div class="text-right">{{ formState.incidentRequest.description?.length }}/900</div>
          </div>

          <ErrorMessage
            :message="$t('forms.incidentForm.steps.v1.description.error')"
            v-if="formTriggers.description && formState.getDescriptionError"
          ></ErrorMessage>
        </TabPanel>

        <!-- Summary -->
        <TabPanel header="{{ $t('forms.incidentForm.summary.title') }}">
          <div class="header pb-4">
            <h2 class="title m-0 text-2xl">
              {{ $t('forms.incidentForm.summary.title') }}
            </h2>
          </div>
          <Incident
            v-if="formState.currentStep === 5"
            :incident="formState.incidentRequest"
          ></Incident>
        </TabPanel>
      </TabView>

      <div class="nav grid mx-3 mb-3">
        <div class="col-6 flex justify-content-start">
          <pv-button
            v-if="formState.currentStep > 0 && !formState.submitted"
            :label="$t('forms.incidentForm.messages.back')"
            @click="back"
            icon="pi pi-angle-left"
            size="large"
            class="nn-button w-auto"
            text
          />
        </div>
        <div class="col-6 flex justify-content-end">
          <pv-button
            v-if="formState.currentStep < 5 && !formState.submitted"
            :label="$t('forms.incidentForm.messages.next')"
            @click="next"
            icon="pi pi-angle-right"
            iconPos="right"
            size="large"
            class="nn-button w-auto flex-grow-0"
          />
          <pv-button
            v-if="formState.currentStep === 5 && !formState.submitted"
            :label="$t('forms.incidentForm.messages.submit')"
            @click="submit"
            id="submit"
            severity="secondary"
            size="large"
            class="nn-button w-auto"
          />
        </div>
      </div>

      <div v-if="formState.submitted">
        <div class="mx-3 my-5 px-3 pb-5" style="background-color: rgb(176, 218, 241)">
          <div class="justify-content-start flex flex-wrap">
            <h2 style="color: rgb(48, 48, 48)">
              {{ $t('forms.incidentForm.messages.successMessage.title') }}
            </h2>
          </div>
          <div>
            <p class="m-0">{{ $t('forms.incidentForm.messages.successMessage.content1') }}</p>
            <p class="m-0">{{ $t('forms.incidentForm.messages.successMessage.content2') }}</p>
          </div>
        </div>
        <div class="flex flex-column align-items-center gap-3 px-3">
          <pv-button
            :label="$t('forms.incidentForm.summary.share_again')"
            @click="reload"
            size="large"
            class="nn-button w-auto"
          />
          <pv-button
            v-if="incident"
            :label="$t('forms.incidentForm.summary.view_on_map')"
            @click="goToIncidentSummary(incident.id)"
            link
            iconPos="right"
            icon="pi pi-angle-right"
            size="large"
            class="nn-button w-auto flex-shrink-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onMounted, ref, type Ref } from 'vue'
import dayjs from 'dayjs'
import { useIncidentFormStore, useIncidentStore } from '@/stores'
import type { FormOption, FormOptionType, PersonForm } from '@/stores/incident-form.store.types'
import {
  IS_INDIVIDUAL,
  type Method,
  type IsIndividual,
  type Appearance
} from '@/stores/incident.store.types'
import type { Gender, IncidentDto } from '@/stores/incident.store.types'
import type { AgeGroup } from '@/stores/incident.store.types'
import { INCIDENT_DATE_FORMAT } from '@/utils/constants'
import Incident from '@/components/storyblok/apps/nn-incident/nn-incident.incident.vue'
import { goToIncidentSummary, scrollToTop } from '@/utils/utils'
import type { NNPlace } from '@/models/google-maps'
import { isObjectFilled } from './common/helpers'
import { appearanceChanged } from './common/form'
import { sanitizeLatLng } from '@/google-maps/utils'

defineProps({
  blok: Object
})

let isMounted = ref()

const formState = useIncidentFormStore()
const incidentState = useIncidentStore()

const incident: Ref<IncidentDto | undefined> = ref()
const minDate = ref(dayjs().year(2020).startOf('year').toDate())
const maxDate = ref(dayjs().endOf('day').toDate())
const calendarDate = ref(dayjs(formState.incidentRequest.date).toDate())
const location = ref()
const nnPlace: Ref<NNPlace | undefined> = ref()

if (formState.incidentRequest.location.latitude && formState.incidentRequest.location.longitude) {
  location.value = {
    position: {
      lat: formState.incidentRequest.location.latitude,
      lng: formState.incidentRequest.location.longitude
    }
  }

  nnPlace.value = {
    geometry: {
      location: new google.maps.LatLng(location.value.position.lat, location.value.position.lng)
    },
    formatted_address: formState.address,
    name: formState.placeName,
    place_id: formState.place_id
  }
}

const formTriggers: any = ref({ culprit: {}, target: {} })
formTriggers.value.method = formState.incidentRequest.method.length > 0
formTriggers.value.date = !!formState.incidentRequest.date
formTriggers.value.culprit.isIndividual = formState.incidentRequest.culprit.isIndividual != null
formTriggers.value.culprit.appearance = formState.incidentRequest.culprit.appearance?.length > 0
formTriggers.value.culprit.gender = formState.incidentRequest.culprit.gender?.length > 0
formTriggers.value.culprit.ageGroup = formState.incidentRequest.culprit.ageGroup?.length > 0
formTriggers.value.target.isIndividual = formState.incidentRequest.target.isIndividual != null
formTriggers.value.target.appearance = formState.incidentRequest.target.appearance?.length > 0
formTriggers.value.target.gender = formState.incidentRequest.target.gender?.length > 0
formTriggers.value.target.ageGroup = formState.incidentRequest.target.ageGroup?.length > 0
formTriggers.value.location =
  formState.incidentRequest.location.latitude && formState.incidentRequest.location.longitude
formTriggers.value.description = !!formState.incidentRequest.description

const methodChanged = ({ option }: { index: number; option: FormOption<Method> }) => {
  formTriggers.value.method = true

  if (!formState.incidentRequest.method) {
    formState.incidentRequest.method = []
  }

  if (option.selected) {
    if (!formState.incidentRequest.method.includes(option.value)) {
      formState.incidentRequest.method.push(option.value)
    }
  } else {
    formState.incidentRequest.method = formState.incidentRequest.method.filter(
      (method) => method !== option.value
    )
  }
}

const dateChanged = (date: Date) => {
  formTriggers.value.date = true

  formState.incidentRequest.date = dayjs(date)
    .hour(0)
    .minute(0)
    .second(0)
    .format(INCIDENT_DATE_FORMAT)
}

// Collective changed ###########################
function collectiveChanged({
  option,
  options,
  resetFunction,
  person,
  personType
}: {
  options: FormOption<FormOptionType>[]
  option: FormOption<FormOptionType>
  person: PersonForm
  resetFunction: Function
  personType: 'culprit' | 'target'
}) {
  person.isIndividual = options as FormOption<IsIndividual>[]

  if (option.value === IS_INDIVIDUAL.INDIVIDUAL) {
    formState.incidentRequest[personType].isIndividual = true
    resetFunction()
  }

  if (option.value === IS_INDIVIDUAL.GROUP) {
    formState.incidentRequest[personType].isIndividual = false
  }
}
const culpritCollectiveChanged = ({
  option,
  options
}: {
  options: FormOption<IsIndividual>[]
  option: FormOption<IsIndividual>
}) => {
  formTriggers.value.culprit.isIndividual = true

  collectiveChanged({
    option,
    options,
    resetFunction: formState.resetCulprit,
    person: formState.culprit,
    personType: 'culprit'
  })
}
const targetCollectiveChanged = ({
  option,
  options
}: {
  options: FormOption<IsIndividual>[]
  option: FormOption<IsIndividual>
}) => {
  formTriggers.value.target.isIndividual = true

  collectiveChanged({
    option,
    options,
    resetFunction: formState.resetTarget,
    person: formState.target,
    personType: 'target'
  })
}
// ##############################################

// Appearance changed ###########################
const culpritAppearanceChanged = ({ options }: { options: FormOption<Appearance>[] }) => {
  formTriggers.value.culprit.appearance = true

  appearanceChanged(
    {
      options,
      person: 'culprit'
    },
    formState
  )
}
const targetAppearanceChanged = ({ options }: { options: FormOption<Appearance>[] }) => {
  formTriggers.value.target.appearance = true

  appearanceChanged(
    {
      options,
      person: 'target'
    },
    formState
  )
}
// ##############################################

// Gender changed ###############################
function genderChanged({
  options,
  person
}: {
  options: FormOption<Gender>[]
  person: 'culprit' | 'target'
}) {
  formState[person].gender = options

  if (!formState.incidentRequest[person].gender) {
    formState.incidentRequest[person].gender = []
  }

  formState.incidentRequest[person].gender = formState[person].gender
    .filter((option) => option.selected)
    .map((option) => option.value)
}
const culpritGenderChanged = ({ options }: { options: FormOption<Gender>[] }) => {
  formTriggers.value.culprit.gender = true

  genderChanged({
    options,
    person: 'culprit'
  })
}
const targetGenderChanged = ({ options }: { options: FormOption<Gender>[] }) => {
  formTriggers.value.target.gender = true

  genderChanged({
    options,
    person: 'target'
  })
}
// ##############################################

// Age group changed ############################
function ageGroupChanged({
  options,
  person
}: {
  options: FormOption<AgeGroup>[]
  person: 'culprit' | 'target'
}) {
  formState[person].ageGroup = options

  if (!formState.incidentRequest[person].ageGroup) {
    formState.incidentRequest[person].ageGroup = []
  }

  formState.incidentRequest[person].ageGroup = formState[person].ageGroup
    .filter((option) => option.selected)
    .map((option) => option.value)
}
const culpritAgeGroupChanged = ({ options }: { options: FormOption<AgeGroup>[] }) => {
  formTriggers.value.culprit.ageGroup = true

  ageGroupChanged({
    options,
    person: 'culprit'
  })
}
const targetAgeGroupChanged = ({ options }: { options: FormOption<AgeGroup>[] }) => {
  formTriggers.value.target.ageGroup = true

  ageGroupChanged({
    options,
    person: 'target'
  })
}
// ##############################################

const mapChanged = (place: NNPlace) => {
  formTriggers.value.location = true

  if (!place.geometry?.location) {
    return
  }

  const latLng = sanitizeLatLng(place.geometry?.location?.lat, place.geometry?.location?.lng)

  nnPlace.value = place

  location.value = {
    position: {
      lat: latLng.lat,
      lng: latLng.lng
    }
  }

  formState.address = place.formatted_address || ''
  formState.placeName = place.name
  formState.place_id = place.name
  formState.incidentRequest.location = {
    latitude: latLng.lat,
    longitude: latLng.lng
  }
}

const submit = async () => {
  // for now only validating that the to be submitted object is filled
  if (isObjectFilled(formState.incidentRequest)) {
    incident.value = await incidentState.postIncident(formState.incidentRequest)
    if (incident.value?.id) {
      formState.submitted = true
    }
  }
}

const hasErrors = () => {
  switch (formState.currentStep) {
    case 0:
      return formState.getWhatHappenedError || formState.getDateError
    case 1:
      return (
        formState.getCulpritCollectiveError ||
        formState.getCulpritAppearanceError ||
        formState.getCulpritGenderError ||
        formState.getCulpritAgeGroupError
      )
    case 2:
      return (
        formState.getTargetCollectiveError ||
        formState.getTargetAppearanceError ||
        formState.getTargetGenderError ||
        formState.getTargetAgeGroupError
      )
    case 3:
      return formState.getLocationError
    case 4:
      return formState.getDescriptionError
    default:
      return false
  }
}

const next = () => {
  switch (formState.currentStep) {
    case 0:
      formTriggers.value.method = true
      formTriggers.value.date = true
      break
    case 1:
      formTriggers.value.culprit.isIndividual = true
      formTriggers.value.culprit.appearance = true
      formTriggers.value.culprit.gender = true
      formTriggers.value.culprit.ageGroup = true
      break
    case 2:
      formTriggers.value.target.isIndividual = true
      formTriggers.value.target.appearance = true
      formTriggers.value.target.gender = true
      formTriggers.value.target.ageGroup = true
      break
    case 3:
      formTriggers.value.location = true
      break
    case 4:
      formTriggers.value.description = true
      break
  }

  if (!hasErrors()) {
    formState.currentStep = formState.currentStep + 1
    scrollToTop()
  }
}

const back = () => {
  formState.currentStep = formState.currentStep - 1
  scrollToTop()
}

const getProgress = () => {
  const base = 100 / 5
  switch (formState.currentStep) {
    case 0:
      if (formState.getWhatHappened && formState.getDate) {
        return base
      }
      if (formState.getWhatHappened || formState.getDate) {
        return base / 2
      }
      return 0
    case 1: {
      const culpritQuestions = Object.values(formState.culprit)
      const cCount = culpritQuestions.reduce((total, options) => {
        const selectedItems = (options as any).filter((option: any) => option.selected)
        return total + selectedItems.length
      }, 0)
      return base + (base / 4) * cCount
    }
    case 2: {
      const targetQuestions = Object.values(formState.target)
      const tCount = targetQuestions.reduce((total, options) => {
        const selectedItems = (options as any).filter((option: any) => option.selected)
        return total + selectedItems.length
      }, 0)
      return base * 2 + (base / 4) * tCount
    }
    case 3:
      if (formState.address) {
        return base * 4
      }
      return base * 3
    case 4:
      if (
        formState.incidentRequest.description &&
        formState.incidentRequest.description?.length > 0 &&
        formState.incidentRequest.description?.length <= 900
      ) {
        return base * 5
      }
      return base * 4
    case 5:
      if (formState.submitted) {
        return 100
      }
      return base * 5
    default:
      return 0
  }
}

const resetForm = () => {
  if (formState.submitted) {
    formState.reset()
  }
}

onBeforeMount(() => {
  window.addEventListener('beforeunload', resetForm)
})

onMounted(() => {
  isMounted.value = true
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', resetForm)
  resetForm()
})

function reload() {
  window.open(window.location.href, '_top')
}
</script>

<style lang="scss">
$red: #d64933;
$blue: #004483;
$light-blue: #b0daf1;

.blue {
  color: $blue;
}

.alert-container {
  color: $blue;
  border-color: $light-blue;
}

.header {
  .title {
    color: $blue;
  }
}

.p-tabview-nav-container {
  display: none;
}

.nav {
  .next,
  .next:hover {
    background: $blue;
  }

  #submit {
    &.p-button {
      &.p-button-secondary {
        background: $red;
        border-color: $red;
      }
    }
  }
}

.question {
  border: 1px transparent solid;
}

.error {
  border-color: $red;
}

.p-progressbar.p-progressbar-determinate {
  height: 10px;
  border: 1px $blue solid;
  border-radius: 2px;
  background-color: transparent;

  .p-progressbar-value {
    background: $blue;
  }
}
</style>
