<template>
  <pv-button
    v-if="blok"
    v-editable="blok"
    :label="blok?.name"
    :severity="blok?.type"
    @click="navigate()"
    size="large"
    :class="{
      'nn-button': true,
      ...verticalMarginOptions(blok),
      ...horizontalMarginOptions(blok)
    }"
  />
</template>

<script setup lang="ts">
import type { StoryblokComponentType } from '@storyblok/vue'
import type { SBLink, SBMarginOptions } from '@/models/storyblok'
import { verticalMarginOptions, horizontalMarginOptions } from '@/utils/storyblok-helpers'
import { openLinkUrl } from '@/storyblok/utils'
import { useMenuStore } from '@/stores'

export interface SBButtonOptions extends StoryblokComponentType<string>, SBMarginOptions {
  name: string
  type: string
  link: SBLink[]
}

interface Props {
  blok: SBButtonOptions
}

const props = defineProps<Props>()

const menu = useMenuStore()

const navigate = () => {
  menu.isVisible = false

  const nnLink: SBLink = props.blok?.link?.[0]
  if (!nnLink) {
    return
  }

  openLinkUrl(nnLink)
}
</script>
