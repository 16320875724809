import { createApp } from 'vue'
import App from './App.vue'
import { defineGoogleMaps } from '@/google-maps/setup'
import { defineStoryblok } from '@/storyblok/setup'
import { defineStoryblokComponents } from '@/storyblok/components'
import { definePrimevueComponents } from '@/primevue/components'
import { definePrimeVue } from '@/primevue/setup'

import '@/assets/style/main.scss'
import { definePinia } from '@/pinia/setup'
import { useRouter } from './router'
import { defineI18n } from '@/i18n/setup'
import { defineDayjs } from '@/dayjs/setup'
import { defineComponents } from '@/components/components'
import { getLanguage } from '@/utils/language'
import { defineSentry } from '@/sentry/setup'

const app = createApp(App)
const router = useRouter()

const language = getLanguage()

defineI18n(app)

definePinia(app)

defineDayjs(language)

defineComponents(app)

definePrimeVue(app, language)
definePrimevueComponents(app)

defineStoryblok(app)
defineStoryblokComponents(app)

defineGoogleMaps(app)

defineSentry(app, router)

app.use(router)
app.mount('#app')
