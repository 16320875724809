import type { FormOption, IncidentFormState } from '@/stores/incident-form.store.types'
import type { Appearance } from '@/stores/incident.store.types'

export function appearanceChanged(
  {
    options,
    person
  }: {
    options: FormOption<Appearance>[]
    person: 'culprit' | 'target'
  },
  formState: IncidentFormState
) {
  formState[person].appearance = options

  if (!formState.incidentRequest[person].appearance) {
    formState.incidentRequest[person].appearance = []
  }

  formState.incidentRequest[person].appearance = formState[person].appearance
    .filter((option) => option.selected)
    .map((option) => option.value)
}
