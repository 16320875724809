<template>
  <div v-if="blok" v-editable="blok" :id="blok._uid" class="blok">
    <pv-button
      v-if="auth.authenticated"
      :label="story?.content?.share_incident"
      @click="newIncident()"
      size="large"
      class="nn-button"
    />
    <form v-if="!auth.authenticated" class="card" @submit.prevent="submit">
      <div v-for="field in story?.content?.blocks" :key="field._uid" class="form-field mb-5">
        <!-- Text Field -->
        <template v-if="field.component === 'text_field'">
          <div>
            <span class="p-float-label">
              <InputText
                v-model="form[field.name.toLowerCase()]"
                :id="field._uid"
                :aria-describedby="`${field._uid}-hint`"
                :disabled="submitting || auth.magicLinkSent"
                class="w-full"
                type="text"
              />
              <label :for="field._uid">{{ field.label }}</label>
            </span>
            <small v-if="field.hint" :id="`${field._uid}-hint`">{{ field.hint }}</small>
          </div>
        </template>

        <!-- Button -->
        <template v-if="field.component === 'nn-button'">
          <pv-button
            :label="field.name"
            :loading="submitting"
            :disabled="submitting || auth.magicLinkSent"
            @click="submit()"
            size="large"
            class="nn-button"
          />
        </template>
      </div>
    </form>

    <div
      v-if="auth.magicLinkSent && !auth.authenticated"
      class="section bg-light-blue text-center py-5"
    >
      <p class="m-0">{{ story?.content?.email_sent }}</p>
      <small class="m-0">{{ story?.content?.spam_notice }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores'
import { getFormStory, getStoryLanguage } from '@/utils'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const props = defineProps({
  blok: Object
})

const auth = useAuthStore()

const language = getStoryLanguage()
const { story, form } = await getFormStory(props?.blok?.form, language)

const submitting = ref(false)
const submit = () => {
  if (auth.magicLinkSent || auth.authenticated) {
    return
  }
  if (!form.value.email) {
    return
  }
  submitting.value = true

  auth
    .loginOrCreate({
      email: form.value.email
    })
    .finally(() => {
      submitting.value = false
    })
}

const newIncident = () => {
  const language = getStoryLanguage()
  window.open(`/${language}/incidents/new`, '_top')
}

onMounted(() => {
  if (auth.authenticated) {
    auth.startTimer()
    return
  }

  const urlParams = new URLSearchParams(window.location.search)
  const publicToken = urlParams.get('public_token')
  if (publicToken && publicToken !== import.meta.env.VITE_STYTCH_PUBLIC_TOKEN) {
    return
  }

  const token = urlParams.get('token')
  const tokenType = urlParams.get('stytch_token_type')
  if (token && tokenType === 'magic_links') {
    auth.authenticate({ token }).then(() => {
      let newQuery = { ...route.query }
      delete newQuery['token']
      delete newQuery['stytch_token_type']
      router.replace({
        path: route.path,
        query: newQuery
      })
    })
  }
})
</script>
