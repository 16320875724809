export type UUID = string

export type ISODateTimeString = string

export const METHOD = {
  VERBAL: 0,
  PHYSICAL: 1,
  COMMENT: 2,
  OTHER: 3
} as const
export type Method = (typeof METHOD)[keyof typeof METHOD]
export type MethodLocale = Record<Method, string>
export const methodLocales = {
  [METHOD.PHYSICAL]: 'forms.incidentForm.messages.physicalAggression',
  [METHOD.VERBAL]: 'forms.incidentForm.messages.verbalAggression',
  [METHOD.COMMENT]: 'forms.incidentForm.messages.racistRemark',
  [METHOD.OTHER]: 'forms.incidentForm.messages.otherUnequalTreatment'
}

export const GENDER = {
  MAN: 0,
  WOMAN: 1
} as const
export type Gender = (typeof GENDER)[keyof typeof GENDER]
export type GenderLocale = Record<Method, string>
export const genderLocales = {
  [GENDER.MAN]: 'forms.incidentForm.messages.man',
  [GENDER.WOMAN]: 'forms.incidentForm.messages.woman'
}

export const AGE_GROUP = {
  CHILD: 0,
  ADULT: 1
} as const
export type AgeGroup = (typeof AGE_GROUP)[keyof typeof AGE_GROUP]
export type AgeGroupLocale = Record<Method, string>
export const ageGroupLocales = {
  [AGE_GROUP.CHILD]: 'forms.incidentForm.messages.child',
  [AGE_GROUP.ADULT]: 'forms.incidentForm.messages.adult'
}

export const APPEARANCE = {
  ASIAN: 0,
  BLACK: 1,
  WHITE: 2,
  MENA: 3,
  LATINO: 4,
  INDIGENOUS: 5
} as const
export type Appearance = (typeof APPEARANCE)[keyof typeof APPEARANCE]
export type AppearanceLocale = Record<Appearance, string>
export const appearanceLocales: AppearanceLocale = {
  [APPEARANCE.WHITE]: 'forms.incidentForm.messages.white',
  [APPEARANCE.BLACK]: 'forms.incidentForm.messages.black',
  [APPEARANCE.ASIAN]: 'forms.incidentForm.messages.asian',
  [APPEARANCE.LATINO]: 'forms.incidentForm.messages.latino',
  [APPEARANCE.MENA]: 'forms.incidentForm.messages.mena',
  [APPEARANCE.INDIGENOUS]: 'forms.incidentForm.messages.indigenous_long'
}
export const appearanceLocalesInSummary: AppearanceLocale = {
  ...appearanceLocales,
  [APPEARANCE.INDIGENOUS]: 'forms.incidentForm.messages.indigenous'
}

export const IS_INDIVIDUAL = {
  GROUP: 0,
  INDIVIDUAL: 1
} as const
export type IsIndividual = (typeof IS_INDIVIDUAL)[keyof typeof IS_INDIVIDUAL]
export type IsIndividualLocale = Record<IsIndividual, string>
export const isIndividualLocales: IsIndividualLocale = {
  [1]: 'forms.incidentForm.messages.individual',
  [0]: 'forms.incidentForm.messages.group'
}

/**
 * Location in geographical coordinates
 * @property longitude - East-west position in degrees
 * @property latitude - North-south position in degrees
 */
export interface Location {
  longitude: number
  latitude: number
}

export interface Person {
  isIndividual: boolean
  gender: Gender[]
  ageGroup: AgeGroup[]
  appearance: Appearance[]
}

export interface IncidentDto {
  id: UUID
  timestamp: ISODateTimeString
  date: ISODateTimeString
  method: Method[]
  description: string
  disabled: boolean
  location: Location
  culprit: Person
  target: Person
}

export type SimpleIncidentResponse = Pick<IncidentDto, 'id' | 'location'>

export type IncidentType = IncidentDto | SimpleIncidentResponse

export interface IncidentState {
  incidents: IncidentType[]
  currentIncidentId: UUID | null
  lastIncidentsFetched: number
}

export interface IncidentResponse {
  incidentId: UUID
  isSuccessful: boolean
  reason: string | null
}

export type IncidentRequest = Omit<IncidentDto, 'id' | 'timestamp'>

export type PersonType = 'culprit' | 'target'
