import type { MultiSelectOption } from '@/stores/incident-form.store.types'
import dayjs from 'dayjs'

// Check if an object is filled
export const isObjectFilled = (obj: any): boolean => {
  // If obj is null or undefined, return false
  if (obj === null || obj === undefined) {
    return false
  }

  // If obj is not an object but a primitive, return true
  if (typeof obj !== 'object') {
    return true
  }

  // If obj is an array, check if every element is filled
  if (Array.isArray(obj)) {
    return obj.every(isObjectFilled)
  }

  // If obj is an object, check if every property is filled
  return Object.values(obj).every(isObjectFilled)
}

export const yearQuestionItems = (minDate: Date, maxDate: Date): MultiSelectOption[] => {
  const minYear = dayjs(minDate).year()
  const maxYear = dayjs(maxDate).year()
  const yearArray: MultiSelectOption[] = []
  for (let year = minYear; year <= maxYear; year++) {
    yearArray.push({
      name: String(year),
      value: String(year),
      selected: year === maxYear
    })
  }
  yearArray.sort((a, b) => {
    return Number(b.value) - Number(a.value)
  })
  return yearArray
}
