import type { Language } from '@/models/languages'
import { getLanguage } from './language'
import { useStoryblok, useStoryblokApi, type ISbResult, type ISbStoryData } from '@storyblok/vue'
import { ref } from 'vue'

export function getStoryLanguage() {
  const pathnames = location.pathname.split('/')
  return getLanguage(pathnames[1])
}

export function getStoryName() {
  const pathnames = location.pathname.split('/')

  if (!pathnames[2]) {
    return 'home'
  }

  if (pathnames[3]) {
    return `${pathnames[2]}/${pathnames[3]}`
  }

  return pathnames[2]
}

export async function getStory(name: string, language: string) {
  try {
    const story = await useStoryblok(name, {
      language,
      version: import.meta.env.VITE_STORYBLOK_VERSION
    })

    if (!story || !story.value) {
      throw new Error('Story object missing')
    }

    return story
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getFormStory(formName: string, language: string) {
  const form: { [key: string]: any } = ref({})
  const story = await getStory(`forms/${formName}`, language)

  const fields = story?.value?.content?.fields
  if (fields) {
    for (const field of fields) {
      form.value[field.name.toLowerCase()] = null
    }
  }

  return {
    story,
    form
  }
}

export async function fetchStory(
  name: string,
  language: Language
): Promise<ISbStoryData | undefined> {
  const storyblokApi = useStoryblokApi()

  try {
    const { data }: ISbResult = await storyblokApi.get(`cdn/stories/${name}`, {
      language,
      version: import.meta.env.VITE_STORYBLOK_VERSION
    })

    if (!data.story) {
      throw new Error('Story object missing')
    }

    return data.story
  } catch (error) {
    // https://www.storyblok.com/docs/api/content-delivery/v2#topics/errors
    console.error(error)
  }
}
