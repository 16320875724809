<template>
  <footer
    v-if="story?.content"
    v-editable="story?.content"
    :id="story?.content._uid"
    class="flex-grow-0"
  >
    <div
      :class="{
      ...verticalPaddingOptions(story?.content as SBPaddingOptions),
      ...horizontalPaddingOptions(story?.content as SBPaddingOptions),
      ...verticalMarginOptions(story?.content as SBMarginOptions),
      ...horizontalMarginOptions(story?.content as SBMarginOptions)
    }"
      class="nn-footer"
    >
      <div class="grid grid-nogutter justify-content-center">
        <LanguageButton />
      </div>
      <div class="grid mx-3">
        <template v-for="(link, index) in story?.content?.links" :key="link._uid">
          <div class="col flex justify-content-center">
            <pv-button :label="link.name" link text @click="linkClicked(link)" />
          </div>
          <div
            v-if="index !== story.content.links.length - 1"
            class="col flex justify-content-center align-items-center"
          >
            <i class="pi pi-circle-fill"></i>
          </div>
        </template>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import LanguageButton from '@/components/nono/language-buttons.vue'

import { getStory, getStoryLanguage } from '@/utils'
import type { StoryblokComponentType } from '@storyblok/vue'
import type { SBMarginOptions, SBPaddingOptions } from '@/models/storyblok'
import {
  verticalPaddingOptions,
  horizontalPaddingOptions,
  verticalMarginOptions,
  horizontalMarginOptions
} from '@/utils/storyblok-helpers'
import { getLanguage, startsWithSupportedLanguage } from '@/utils/language'

interface Link {
  url: {
    id: string
    url: string
    linktype: string
    fieldtype: string
    cached_url: string
    prep: boolean
  }
  _uid: string
  name: string
  component: string
  _editable: string
}

interface SBFooterOptions
  extends StoryblokComponentType<string>,
    SBPaddingOptions,
    SBMarginOptions {
  links: Link[]
}

interface Props {
  blok: SBFooterOptions
}

defineProps<Props>()

const language = getStoryLanguage()
const story = await getStory('footers/main-footer', language)

const linkClicked = (link: Link) => {
  const url = link.url?.url || link.url?.cached_url

  if (link.url?.linktype === 'story') {
    if (!startsWithSupportedLanguage(url)) {
      const language = getLanguage()
      window.open(`/${language}/${url}`, '_top')
      return
    }
  }
  window.open(url, '_top')
  return
}
</script>
