import { acceptHMRUpdate, defineStore } from 'pinia'
import dayjs from 'dayjs'
import { AUTH_ID } from './constants'
import { LOGIN_EXPIRY_TIME, TIME_FORMAT } from '@/utils/constants'
import type {
  AuthenticationRequest,
  AuthenticationResponse,
  AuthState,
  StytchAuthenticationRequest,
  StytchUserResponse
} from './auth.store.types'

export const useAuthStore = defineStore({
  id: AUTH_ID,
  state: (): AuthState => ({ ...getDefaultState() }),
  getters: {
    lastLogin: (state): Date | null => {
      const time = dayjs(state.userData?.lastLogin, TIME_FORMAT)
      return time.isValid() ? time.toDate() : null
    }
  },
  actions: {
    loginOrCreate(parameters: AuthenticationRequest) {
      return fetch(`${import.meta.env.VITE_API_LOGIN_LINK}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': import.meta.env.VITE_API_KEY
        },
        body: JSON.stringify(parameters)
      })
        .then((response) => response.json())
        .then((data: AuthenticationResponse) => {
          this.magicLinkSent = true
        })
        .catch((error: any) => {
          console.error(error)
          this.error = true
        })
    },
    authenticate(parameters: StytchAuthenticationRequest) {
      return fetch(`${import.meta.env.VITE_API_AUTHENTICATION_LINK}`, {
        cache: 'no-cache',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': import.meta.env.VITE_API_KEY
        },
        body: JSON.stringify(parameters)
      })
        .then((response) => response.json())
        .then((data: AuthenticationResponse) => {
          if (data.isSuccess || data.statusCode === 200) {
            this.userData = data
            this.authenticated = true
          }

          throw new Error(`Error with auth repsonse ${data}`)
        })
        .catch((error: any) => {
          console.error('authenticate error', error)
          this.error = true
        })
        .finally(() => {
          this.magicLinkSent = false
          // this.startTimer()
        })
    },
    verifyToken(parameters: StytchAuthenticationRequest) {
      fetch(`${import.meta.env.VITE_API_VERIFY_TOKEN_LINK}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': import.meta.env.VITE_API_KEY
        },
        body: JSON.stringify(parameters)
      })
        .then((response) => response.json())
        .then((data: StytchUserResponse) => {
          if (data.status_code === 200) {
            this.authenticated = true
            // this.startTimer()
          }
        })
        .catch((error: any) => {
          console.error('authenticate error', error)
          this.error = true
        })
    },
    startTimer() {
      if (this.timerId) {
        clearTimeout(this.timerId)
        this.timerId = null
      }

      if (!this.lastLogin) return

      const expiry = this.lastLogin.getTime() + LOGIN_EXPIRY_TIME * 60 * 1000
      const timeRemaining = expiry - new Date().getTime()
      console.log('Last login was at', this.lastLogin)
      console.log(`${timeRemaining} milliseconds before re-authentication needed`)

      if (timeRemaining > 0) {
        const jitter = Math.random() * 5 * 60 * 1000
        const delay = timeRemaining - jitter
        this.timerId = setTimeout(() => {
          if (!this.userData) return
          this.verifyToken({ token: this.userData.sessionToken })
        }, delay) as unknown as number
        return
      }

      this.reset()
    },
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    }
  },
  persist: {
    storage: sessionStorage
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}

function getDefaultState(): AuthState {
  return {
    error: false,
    magicLinkSent: false,
    authenticated: false,
    userData: {} as AuthenticationResponse,
    timerId: 0
  }
}
