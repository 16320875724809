<template>
  <div class="pb-4">
    <p class="nn-text text-sm" v-if="personType === 'culprit'">
      {{
        $t(`forms.incidentForm.summary.appearanceOfPerson`, {
          person: $t(`common.culprit`)
        })
      }}
    </p>
    <p class="nn-text text-sm" v-if="personType === 'target'">
      <span class="capitalize">{{ $t(`common.target`) }}</span> {{ $t(`common.of`) }}
      {{ $t(`common.racism`) }}
    </p>
    <div v-if="!onlyAppearance">
      <p class="nn-text color-blue">{{ incidentPersonFormatted(person) }}</p>
      <div v-if="isPersonComplex(person)">
        <PVChip
          v-for="(tag, index) in incidentPersonTags(person)"
          :key="index"
          :label="tag"
          class="chip text-xs mr-1 mb-1"
        />
      </div>
    </div>
    <div class="sm:flex" v-if="onlyAppearance">
      <p
        v-for="(tag, index) in incidentPersonTags(person)"
        :key="index"
        class="nn-text color-blue mr-1 mb-1"
      >
        {{ tag }}<span v-if="index !== person.appearance.length - 1">,</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Person, PersonType } from '@/stores/incident.store.types'
import {
  incidentPersonFormatted,
  isPersonComplex,
  incidentPersonTags
} from '@/stores/incident.store.fns'
import { inject } from 'vue'

interface Props {
  person: Person
  personType: PersonType
}

defineProps<Props>()

const onlyAppearance = inject('onlyAppearance')
</script>
