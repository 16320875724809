<template>
  <ToggleButton
    class="w-full text-left mb-2"
    v-for="(option, index) in options"
    v-model="option.selected"
    :key="index + option.name + option.value"
    :onLabel="label(option.name)"
    :offLabel="label(option.name)"
    :aria-label="label(option.name)"
    @change="selected(index, option)"
  />
</template>

<script setup lang="ts">
import { getTranslation } from '@/i18n/utils'
import type { MultiSelectOption } from '@/stores/incident-form.store.types'
import type { Ref } from 'vue'
import { onMounted } from 'vue'
import { ref } from 'vue'

export interface MultiSelectEvent {
  index: number
  options: MultiSelectOption[]
  option: MultiSelectOption
}
interface Props {
  blok: MultiSelectOption[]
  single?: boolean
  translate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  single: false,
  translate: true
})

const emit = defineEmits<{
  (e: 'change', value: MultiSelectEvent): void
}>()

const options: Ref<MultiSelectOption[]> = ref([])

const selected = (index: number, option: MultiSelectOption) => {
  if (props.single) {
    options.value = options.value.map((option) => ({ ...option, selected: false }))
    options.value[index].selected = true
  }

  emit('change', {
    index,
    options: options.value,
    option
  })
}

onMounted(() => {
  options.value = props.blok
})

function label(name: string) {
  const t = getTranslation()
  return props.translate ? t(name) : name
}
</script>

<style scoped lang="scss">
$blue: #004483;
$greyselect: #f1f1f1;

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover {
  background: $blue;
  border-color: $blue;
}
.p-togglebutton.p-button {
  background: $greyselect;
  border-color: $greyselect;
}
</style>
