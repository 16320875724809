import type { SBLink } from '@/models/storyblok'
import { getLanguage, startsWithSupportedLanguage } from '@/utils/language'

export function openLinkUrl(link: SBLink) {
  const url = getLinkUrl(link)
  window.open(url, link.url.target || '_top')
}

function getLinkUrl(link: SBLink) {
  const linkUrl = link.url
  let url = linkUrl.url || linkUrl.cached_url

  // // Type: URL
  // if (linkType === 'url') {
  // }
  // // Type: Asset
  // if (linkType === 'asset') {
  // }

  // Type: Internal Link
  if (linkUrl.linktype === 'story') {
    if (!startsWithSupportedLanguage(url)) {
      const language = getLanguage()
      url = `/${language}/${url}`
    }
  }

  // Type: Email
  if (linkUrl.linktype === 'email') {
    url = `/mailto:${url}`
  }

  return url
}
