import { useI18n } from 'vue-i18n'
import type { VueI18n } from 'vue-i18n'
import { Language } from '@/models/languages'
import { getLanguage } from '@/utils/language'
import setupI18n from './setup'

export function getTranslation() {
  const { t } = setupI18n({ locale: getLanguage() }).global as VueI18n
  return t
}

export function changeI18nLanguage(language: Language = Language.ENGLISH) {
  const { locale } = useI18n({ useScope: 'global' })
  if (locale.value !== language) {
    locale.value = language
  }
}
