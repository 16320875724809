import { INCIDENT_DATE_DISPLAY_FORMAT, INCIDENT_DATE_FORMAT } from '@/utils/constants'
import {
  type IncidentDto,
  type Person,
  type Location,
  type Method,
  methodLocales,
  type Appearance,
  ageGroupLocales,
  appearanceLocales,
  genderLocales,
  type AgeGroup,
  type Gender,
  appearanceLocalesInSummary,
  type IncidentType
} from './incident.store.types'
import dayjs from 'dayjs'
import { getTranslation } from '@/i18n/utils'
import { calculateDistance } from '@/utils/location'

// Check if satisfies IncidentDto ###############
export function isIncidentDto(incident: any): incident is IncidentDto {
  const keys: (keyof IncidentDto)[] = [
    'id',
    'timestamp',
    'date',
    'method',
    'description',
    'disabled',
    'location',
    'culprit',
    'target'
  ]

  // Check if the incident has all the required keys
  if (!incident || keys.some((key) => !(key in incident))) {
    return false
  }

  // Check the type of each property
  return (
    typeof incident.id === 'string' &&
    typeof incident.timestamp === 'string' &&
    typeof incident.date === 'string' &&
    Array.isArray(incident.method) &&
    typeof incident.description === 'string' &&
    typeof incident.disabled === 'boolean' &&
    isLocation(incident.location) &&
    isPerson(incident.culprit) &&
    isPerson(incident.target)
  )
}
function isLocation(location: any): location is Location {
  return (
    typeof location === 'object' &&
    location !== null &&
    typeof location.longitude === 'number' &&
    !isNaN(location.longitude) &&
    typeof location.latitude === 'number' &&
    !isNaN(location.latitude)
  )
}
function isPerson(person: any): person is Person {
  return (
    typeof person === 'object' &&
    person !== null &&
    typeof person.isIndividual === 'boolean' &&
    Array.isArray(person.gender) &&
    Array.isArray(person.ageGroup) &&
    Array.isArray(person.appearance)
  )
}
// ##############################################

export function incidentDateFormatted(date: string): string {
  const d = dayjs(date, INCIDENT_DATE_FORMAT)
  if (!d.isValid()) {
    return ''
  }
  return d.format(INCIDENT_DATE_DISPLAY_FORMAT)
}

export function incidentMethodFormatted(methods: Method[]): string {
  const t = getTranslation()

  const method = methods.map((m) => {
    return t(methodLocales[m])
  })
  if (!method || !method.length) {
    return ''
  }

  if (method.length > 1) {
    const output = `${method.join(' + ')}`
    return output
  }

  return method[0]
}

// Formatted Person description #################
export function incidentPersonFormatted(person: Person): string {
  const isIndividual = person.isIndividual
  const appearance = person.appearance || []
  const gender = person.gender || []
  const ageGroup = person.ageGroup || []

  if (!gender.length || (!ageGroup.length && appearance.length)) {
    const t = getTranslation()
    if (appearance.length > 1) {
      return `${t('forms.incidentForm.summary.summary.groupComplex')}`
    }
    return `${t(appearanceLocalesInSummary[appearance[0]])}`
  } else if (!appearance.length || !gender.length || !ageGroup.length) {
    return ''
  }

  return incidentPersonDescription(isIndividual, appearance, gender, ageGroup)
}
function incidentPersonDescription(
  isIndividual: boolean,
  appearance: Appearance[],
  gender: Gender[],
  ageGroup: AgeGroup[]
): string {
  const t = getTranslation()

  if (isIndividual) {
    const noun = `forms.incidentForm.summary.summary.${ageGroupLocales[ageGroup[0]]
      .split('.')
      .pop()}.${genderLocales[gender[0]].split('.').pop()}`
    return `${t(appearanceLocalesInSummary[appearance[0]], { count: 2 })} ${t(noun, { count: 0 })}`
  } else {
    if (appearance.length === 1) {
      if (ageGroup.length > 1) {
        return `${t('forms.incidentForm.summary.summary.group')} ${t(
          appearanceLocalesInSummary[appearance[0]],
          { count: 2 }
        )} ${t('forms.incidentForm.summary.summary.people', { count: 1 })}`
      } else if (gender.length > 1) {
        const noun = `forms.incidentForm.summary.summary.${ageGroupLocales[ageGroup[0]]
          .split('.')
          .pop()}.${genderLocales[gender[0]].split('.').pop()}`

        return `${t('forms.incidentForm.summary.summary.group')} ${t(
          appearanceLocalesInSummary[appearance[0]],
          { count: 2 }
        )} ${t(noun, { count: 3 })}`
      } else {
        const noun = `forms.incidentForm.summary.summary.${ageGroupLocales[ageGroup[0]]
          .split('.')
          .pop()}.${genderLocales[gender[0]].split('.').pop()}`

        return `${t('forms.incidentForm.summary.summary.group')} ${t(
          appearanceLocalesInSummary[appearance[0]],
          { count: 2 }
        )} ${t(noun, { count: 1 })}`
      }
    } else {
      return `${t('forms.incidentForm.summary.summary.groupComplex')}`
    }
  }
}
export function isPersonComplex(person: Person): boolean {
  return !person.isIndividual && (person.appearance || []).length > 1
}
export function incidentPersonTags(person: Person): string[] {
  const t = getTranslation()

  const appearance = (person.appearance || []).map((m) => t(appearanceLocales[m]))
  const gender = (person.gender || []).map((m) => t(genderLocales[m]))
  const ageGroup = (person.ageGroup || []).map((m) => t(ageGroupLocales[m]))
  return appearance.concat(gender, ageGroup)
}
// ##############################################

export function filterIncidents(
  incidents: IncidentType[],
  latitude: number,
  longitude: number,
  radius: number
): IncidentType[] {
  const filteredIncidents = []
  for (const incident of incidents) {
    if (incident.location && incident.location.latitude && incident.location.longitude) {
      const incidentLat = incident.location.latitude
      const incidentLon = incident.location.longitude
      const distance = calculateDistance(latitude, longitude, incidentLat, incidentLon)
      if (distance <= radius) {
        filteredIncidents.push(incident)
      }
    }
  }
  return filteredIncidents
}
