import { BREAKPOINT } from './constants'

// Transform image url from Storyblok into custom sized url
export const transformImage = (image: string) => {
  let width: number = BREAKPOINT.XL
  const screenWidth = window.innerWidth

  for (const point of Object.values(BREAKPOINT)) {
    if (screenWidth <= point && point <= width) {
      width = point
    }
  }

  const resolution = `${width}x0`
  return image.replace(
    'https://a.storyblok.com',
    `https://img2.storyblok.com/${resolution}/filters:quality(75):format(webp)`
  )
}
