import type { IncidentDto } from '@/stores/incident.store.types'
import dayjs from 'dayjs'

export function sortIncidents(incidents: IncidentDto[]) {
  const sortedIncidents = incidents.sort((a, b) => {
    const dateA = a.date ? dayjs(a.date).toDate() : 0
    const dateB = b.date ? dayjs(b.date).toDate() : 0

    if (dateA > dateB) {
      return -1
    } else if (dateA < dateB) {
      return 1
    } else {
      const timestampA = a.timestamp ? dayjs(a.timestamp).toDate() : 0
      const timestampB = b.timestamp ? dayjs(b.timestamp).toDate() : 0

      return (timestampB as number) - (timestampA as number)
    }
  })

  return sortedIncidents
}
