<template>
  <div v-editable="blok" class="m-3">
    <h2 v-if="story?.content?.title">{{ story?.content?.title }}</h2>
    <p v-if="story?.content?.required_description" class="flex justify-content-end">
      {{ story?.content?.required_description }}
    </p>
    <form class="card">
      <div v-for="field in story?.content?.fields" :key="field._uid" class="field mb-5">
        <!-- Text Field -->
        <template v-if="field.component === 'text_field'">
          <span class="p-float-label">
            <InputText
              v-model="form[field.name]"
              :id="field._uid"
              :aria-describedby="`${field._uid}-hint`"
              class="w-full"
              type="text"
            />
            <label :for="field._uid">{{ field.name }}</label>
          </span>
          <small v-if="field.hint" :id="`${field._uid}-hint`">{{ field.hint }}</small>
        </template>

        <!-- Textarea Field -->
        <template v-if="field.component === 'textarea_field'">
          <span class="p-float-label">
            <pv-textarea
              v-model="form[field.name]"
              :id="field._uid"
              :aria-describedby="`${field._uid}-hint`"
              rows="10"
              class="w-full"
            />
            <label :for="field._uid">{{ field.name }}</label>
          </span>
          <small v-if="field.hint" :id="`${field._uid}-hint`">{{ field.hint }}</small>
        </template>
      </div>
    </form>
    <pv-button
      :label="story?.content?.submit_title"
      @click="submit()"
      class="button p-button-lg flex justify-content-center"
    />
  </div>
</template>

<script setup lang="ts">
import { getFormStory, getStoryLanguage } from '@/utils'

const props = defineProps({
  blok: Object
})

const language = getStoryLanguage()
const { story, form } = await getFormStory(props?.blok?.form, language)

const submit = () => {
  console.log('submit')
  // TODO: how to send email? MailChimp?
}
</script>
