import { defineStore, acceptHMRUpdate } from 'pinia'
import type { PiniaPluginContext } from 'pinia'
import { INCIDENT_ID } from './constants'
import type {
  IncidentDto,
  IncidentRequest,
  IncidentResponse,
  IncidentState,
  IncidentType,
  SimpleIncidentResponse,
  UUID
} from './incident.store.types'
import { CLEANUP_INTERVAL } from '@/utils/constants'
import { filterIncidents, isIncidentDto } from './incident.store.fns'
import { useAuthStore } from './auth.store'

const POI_RADIUS = 0

export const useIncidentStore = defineStore({
  id: INCIDENT_ID,
  state: (): IncidentState => ({ ...getDefaultState() }),
  getters: {
    currentIncident(): IncidentDto | undefined {
      if (!this.currentIncidentId) {
        return
      }

      const incident = this.incidents.find((incident) => incident.id === this.currentIncidentId)
      return incident as IncidentDto
    },
    incidentsInRadius(): IncidentType[] {
      if (!this.currentIncident) {
        return []
      }

      const incidents = filterIncidents(
        this.incidents,
        this.currentIncident.location.latitude,
        this.currentIncident.location.longitude,
        POI_RADIUS
      )
      return incidents
    }
  },
  actions: {
    addIncident(incident: IncidentType) {
      const index = this.incidents.findIndex((i) => i.id === incident.id)
      if (index !== -1) {
        this.incidents[index] = {
          ...this.incidents[index],
          ...incident
        }
      } else {
        this.incidents = [...this.incidents, incident]
      }
    },
    addIncidents(incidents: IncidentType[]) {
      incidents.forEach((incident) => this.addIncident(incident))
    },
    removeIncidentsWithoutId() {
      this.incidents = this.incidents.filter((incident) => incident.id)
      localStorage.setItem('lastCleanup', String(Date.now()))
    },
    async postIncident(incidentReq: IncidentRequest): Promise<IncidentDto> {
      try {
        const authState = useAuthStore()
        const userData = authState.userData

        if (!userData?.userId || !userData?.sessionToken) {
          throw new Error('no authenticated')
        }

        const response = await fetch(`${import.meta.env.VITE_API_INCIDENTS_LINK}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': import.meta.env.VITE_API_KEY,
            'X-Stytch-Token': userData.sessionToken
          },
          body: JSON.stringify({
            ...incidentReq,
            userId: userData.userId
          })
        })
        if (!response.ok) {
          throw new Error('Failed to post incident')
        }

        const data: IncidentResponse = await response.json()
        if (!data.isSuccessful) {
          throw new Error(`Submitting incident not successful: ${data}`)
        }

        const incident = { ...incidentReq, ...{ id: data.incidentId } } as IncidentDto
        this.addIncident(incident)
        return incident
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async getIncidents() {
      const currentTimestamp = Date.now()
      if (currentTimestamp - this.lastIncidentsFetched < 10000) {
        return this.incidents
      }

      const response = await fetch(`${import.meta.env.VITE_API_INCIDENTS_LINK}`, {
        method: 'GET',
        headers: {
          'X-Api-Key': import.meta.env.VITE_API_KEY
        }
      })
      if (!response.ok) {
        throw new Error('Failed to fetch incidents')
      }
      const incidents: SimpleIncidentResponse[] = await response.json()
      this.addIncidents(incidents)

      this.lastIncidentsFetched = Date.now()
      return incidents
    },
    async getIncident(uuid: UUID) {
      const localIncident = this.incidents.find((incident) => incident.id === uuid)
      if (isIncidentDto(localIncident)) {
        return localIncident
      }

      const response = await fetch(`${import.meta.env.VITE_API_INCIDENTS_LINK}/${uuid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': import.meta.env.VITE_API_KEY
        },
        cache: 'default'
      })
      if (!response.ok) {
        throw new Error('Failed to fetch incident')
      } else if (response.status === 404) {
        throw new Error('Not found')
      }

      const incident: IncidentDto = await response.json()
      this.addIncident(incident)

      return incident
    },
    async getIncidentsInRadius(): Promise<IncidentType[]> {
      if (!this.currentIncident) {
        return new Promise((resolve) => resolve([]))
      }

      const incidents = this.incidentsInRadius

      for (let incident of incidents) {
        if (!incident || !isIncidentDto(incident || {})) {
          const incidentResp = await this.getIncident(incident.id)
          if (!incidentResp) {
            continue
          }
          incident = incidentResp
        }
      }

      return new Promise((resolve) => resolve(incidents))
    },
    startCleanup() {
      const lastCleanup = Number(localStorage.getItem('lastCleanup'))
      const now = Date.now()
      const remaining = lastCleanup ? Math.max(0, CLEANUP_INTERVAL - (now - lastCleanup)) : 0

      setTimeout(() => {
        this.removeIncidentsWithoutId()
        this.startCleanup()
      }, remaining)
    },
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    }
  },
  persist: {
    storage: localStorage,
    afterRestore: (ctx: PiniaPluginContext) => {
      ctx.store.startCleanup()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIncidentStore, import.meta.hot))
}

function getDefaultState(): IncidentState {
  return { incidents: [], currentIncidentId: null, lastIncidentsFetched: 0 }
}
