<template>
  <template v-if="blok">
    <div
      v-show="showBlok"
      v-editable="blok"
      :style="{ 'background-color': blok.backgroundcolor }"
      :class="{
        'nn-section': true,
        ...verticalPaddingOptions(blok),
        ...horizontalPaddingOptions(blok),
        ...verticalMarginOptions(blok),
        ...horizontalMarginOptions(blok)
      }"
    >
      <template v-if="blok.content">
        <StoryblokComponent v-for="block in blok.content" :key="block._uid" :blok="block" />
      </template>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores'
import { onMounted, ref, watch } from 'vue'
import type { StoryblokComponentType } from '@storyblok/vue'
import type {
  SBBackgroundcolorOptions,
  SBMarginOptions,
  SBPaddingOptions
} from '@/models/storyblok'
import {
  verticalPaddingOptions,
  horizontalPaddingOptions,
  verticalMarginOptions,
  horizontalMarginOptions
} from '@/utils/storyblok-helpers'

export interface SBSectionOptions
  extends StoryblokComponentType<string>,
    SBPaddingOptions,
    SBMarginOptions,
    SBBackgroundcolorOptions {
  content: any[] // TODO: convert to array of the different blocks
  showOnlyToLoggedIn: boolean
  hideWhenLoggedIn: boolean
  hideWhenMagicMailSent: boolean
}

interface Props {
  blok: SBSectionOptions
}

const props = defineProps<Props>()

const auth = useAuthStore()
const showBlok = ref(true)

const checkVisibility = () => {
  if (props.blok?.showOnlyToLoggedIn && !auth.authenticated) {
    showBlok.value = false
  }

  if (props?.blok?.hideWhenLoggedIn && auth.authenticated) {
    showBlok.value = false
  }

  if (props?.blok?.hideWhenMagicMailSent && auth.magicLinkSent) {
    showBlok.value = false
  }
}

watch(auth, async () => {
  checkVisibility()
})

onMounted(() => {
  checkVisibility()
})
</script>
