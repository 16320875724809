import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'
import 'dayjs/locale/nl'
import { Language } from '@/models/languages'

export function defineDayjs(language: Language = Language.ENGLISH) {
  dayjs.extend(utc)
  dayjs.extend(localeData)
  dayjs.locale(language)
}
