import type { App } from 'vue'
import PrimeVue from 'primevue/config'
import { Language } from '@/models/languages'
import * as nl from './locales/nl.json'
import * as en from './locales/en.json'

export function definePrimeVue(app: App, language: Language = Language.ENGLISH) {
  let locales = en
  if (language === Language.DUTCH) {
    locales = nl
  }

  const options = {
    unstyled: false,
    pt: {},
    ripple: true,
    inputStyle: 'filled',
    zIndex: {
      modal: 1100, //dialog, sidebar
      overlay: 1000, //dropdown, overlaypanel
      menu: 1000, //overlay menus
      tooltip: 1100 //tooltip
    },
    locale: locales.locale
  }

  app.use(PrimeVue, options)
}
