<template>
  <template v-if="incidentId && !loading && !error">
    <div v-if="blok && incident" v-editable="blok" :id="blok._uid" class="flex flex-column mx-3">
      <div class="header pb-4">
        <h2 class="title m-0 text-2xl text-center capitalize">{{ $t('common.incident') }}</h2>
      </div>
      <Incident :incident="incident"></Incident>
    </div>
    <div v-if="incidentProp && incident" :id="incident.id" class="flex flex-column mx-3">
      <div class="header pb-4">
        <h2 class="title m-0 text-2xl text-center capitalize">{{ $t('common.incident') }}</h2>
      </div>
      <Incident :incident="incident"></Incident>
    </div>
  </template>

  <template v-else-if="incidentId && error">
    <div class="p-3 m-3 bg-light-blue">
      <NotFound />
    </div>
  </template>

  <template v-else-if="incidentId && loading">
    <nn-loading></nn-loading>
  </template>
</template>

<script setup lang="ts">
import Incident from './nn-incident.incident.vue'
import NotFound from '@/components/nono/NotFound.vue'

import { ref, watch } from 'vue'
import { useIncidentStore } from '@/stores'
import { isIncidentDto } from '@/stores/incident.store.fns'
import { useRoute } from 'vue-router'
import type { StoryblokComponentType } from '@storyblok/vue'
import type { IncidentDto, UUID } from '@/stores/incident.store.types'
import { onMounted } from 'vue'

interface Props {
  blok: StoryblokComponentType<string>
  incidentProp?: IncidentDto
}

const props = defineProps<Props>()

const route = useRoute()

const incidentState = useIncidentStore()

const loading = ref(true)
const error = ref(false)
const incidentId = ref<UUID>('')
const incident = ref<IncidentDto>()

watch(incidentId, async (newIncidentId) => {
  if (!newIncidentId) {
    incidentState.currentIncidentId = null
    return
  }

  try {
    const incidentFromStore = incidentState.currentIncident
    if (!isIncidentDto(incidentFromStore)) {
      incident.value = await incidentState.getIncident(incidentId.value)
    } else {
      incident.value = incidentFromStore
    }
    loading.value = false
  } catch (e) {
    delete incident.value
    error.value = true
  }
})

const updateIncident = () => {
  if (props.incidentProp && isIncidentDto(props.incidentProp)) {
    incident.value = props.incidentProp
  } else if (route.query.incident) {
    incidentState.currentIncidentId = route.query.incident as UUID
    incidentId.value = route.query.incident as UUID
  } else {
    error.value = true
  }
}

onMounted(() => {
  updateIncident()
})
</script>
