<template>
  <div
    v-if="!incidentIdQuery && blok"
    ref="nnIncidentMap"
    v-editable="blok"
    :id="blok._uid"
    :class="{
      'nn-incident-map': true,
      ...verticalPaddingOptions(blok),
      ...horizontalPaddingOptions(blok)
    }"
  >
    <nn-map
      useSearch
      :options="nnMapOptions"
      :markers="markers"
      :useInfoWindow="blok.useInfoWindow"
      @markerClick="markerClicked"
      @ready="googleMapReady"
      @click="incidentStore.currentIncidentId = null"
    >
      <template v-slot:info-window>
        <nn-incident-card v-if="incident" :incidentType="incident"></nn-incident-card>
      </template>
      <template v-slot:dialog>
        <pv-dialog
          v-if="!incidentIdQuery && dialogIncidents?.length"
          v-model:visible="dialogVisible"
          :draggable="false"
          modal
          appendTo=".vue-map :first-child"
          class="nn-incident-map-dialog"
          position="bottom"
          @hide="dialogHidden"
        >
          <nn-incident-card
            v-for="(incident, index) of dialogIncidents"
            :key="index"
            :incidentType="incident"
          ></nn-incident-card>
        </pv-dialog>
      </template>
    </nn-map>
  </div>
</template>

<script setup lang="ts">
import type { MarkerLocation } from '@/models/locations'
import { useIncidentStore } from '@/stores/incident.store'
import { onBeforeMount, watch, type Ref } from 'vue'
import { ref } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import type { StoryblokComponentType } from '@storyblok/vue'
import type { SBPaddingOptions } from '@/models/storyblok'
import { verticalPaddingOptions, horizontalPaddingOptions } from '@/utils/storyblok-helpers'
import { sortIncidents } from '@/utils/sort-incidents'
import type { IncidentDto, IncidentType } from '@/stores/incident.store.types'
import { GESTURE_HANDLING } from '@/models/google-maps'
import { createMarkerSymbol, createMarkerLabel } from '@/google-maps/utils'
import { onMounted } from 'vue'
import {
  GM_SELECTED_MARKER_ANCHOR,
  GM_SELECTED_MARKER_LABELORIGIN,
  GM_SELECTED_MARKER_PATH,
  GM_SELECTED_MARKER_SCALE
} from '@/google-maps/constants'

export interface SBIncidentMapOptions extends StoryblokComponentType<string>, SBPaddingOptions {
  useInfoWindow: boolean
}

interface Props {
  blok: SBIncidentMapOptions
}

const props = defineProps<Props>()

const route = useRoute()
const incidentStore = useIncidentStore()

const incidentIdQuery = ref()
const markers: Ref<MarkerLocation[]> = ref([])
const incident = ref()
const dialogVisible = ref(false)
const dialogIncidents: Ref<IncidentType[]> = ref([])
const map: Ref<google.maps.Map | undefined> = ref()
const nnIncidentMap = ref()

incidentIdQuery.value = route.query.incident

const nnMapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  fullscreenControl: true,
  clickableIcons: false,
  gestureHandling: GESTURE_HANDLING.AUTO,
  rotateControl: false,
  streetViewControl: false,
  scaleControl: true
}

const openIncidentDialog = async () => {
  if (incidentIdQuery.value) return
  if (!incidentStore.currentIncidentId) return
  const incidents = await incidentStore.getIncidentsInRadius()
  if (!incidents?.length) return
  dialogIncidents.value = sortIncidents(incidents as IncidentDto[])
  dialogVisible.value = true
}

const markerClicked = (marker: MarkerLocation) => {
  incidentStore.currentIncidentId = marker.id as string
}

const dialogHidden = () => {
  dialogVisible.value = false
  dialogIncidents.value = []
  incidentStore.currentIncidentId = null
  incidentStore.currentIncidentId = null
}

const googleMapReady = async (googleMap: google.maps.Map) => {
  map.value = googleMap
  markers.value = await createMarkers(incidentStore.incidents)
}

watch([map, incidentStore], async () => {
  if (!map.value || !incidentStore.incidents.length) {
    return
  }
  markers.value = await createMarkers(incidentStore.incidents)
})

watch(incidentStore, async () => {
  if (props.blok.useInfoWindow) {
    incident.value = incidentStore.currentIncident
    return
  }

  await openIncidentDialog()
})

onMounted(async () => {
  await openIncidentDialog()
})

onBeforeMount(async () => {
  await incidentStore.getIncidents()
})

onBeforeRouteLeave(() => {
  incidentStore.currentIncidentId = null
})

async function createMarkers(incidents: IncidentType[]): Promise<MarkerLocation[]> {
  const markers: MarkerLocation[] = incidents.map((incident) => ({
    id: incident.id,
    position: {
      lat: incident.location.latitude,
      lng: incident.location.longitude
    },
    optimized: true,
    icon: createMarkerSymbol({})
  }))

  // Count occurrences of latitude and longitude
  const occurrencesMap = new Map()
  markers.forEach((marker) => {
    const { lat, lng } = marker.position
    const key = `${lat},${lng}`

    if (occurrencesMap.has(key)) {
      occurrencesMap.set(key, occurrencesMap.get(key) + 1)
    } else {
      occurrencesMap.set(key, 1)
    }
  })

  markers.forEach((marker) => {
    const { lat, lng } = marker.position
    const key = `${lat},${lng}`

    if (occurrencesMap.has(key) && occurrencesMap.get(key) > 1) {
      marker.occurrences = String(occurrencesMap.get(key))
      marker.label = createMarkerLabel({ text: String(occurrencesMap.get(key)) })
      marker.icon = createMarkerSymbol({
        path: GM_SELECTED_MARKER_PATH,
        anchor: GM_SELECTED_MARKER_ANCHOR(),
        scale: GM_SELECTED_MARKER_SCALE,
        labelOrigin: GM_SELECTED_MARKER_LABELORIGIN()
      })
    }
  })

  return markers
}

// Checks if the loaded map is the only element on the page
// function isOnlyChild(element: HTMLElement) {
//   const parent = element.parentNode
//   if (!parent) return
//   const children = parent.children
//   return children.length === 1 && children[0] === element
// }
</script>
