import { changeDayjsLanguage } from '@/dayjs/utils'
import { changeI18nLanguage } from '@/i18n/utils'
import { Language } from '@/models/languages'
import { changePrimeVueLanguage } from '@/primevue/utils'

export function getLanguage(language: string = ''): Language {
  if (isSupportedLanguage(language)) {
    return getLanguageEnum(language)
  }

  const langFromURL = getLanguageFromURLSegment()
  if (langFromURL) {
    return langFromURL
  }

  const langFromBrowser = getLanguageFromBrowser()
  if (langFromBrowser) {
    return langFromBrowser
  }

  return Language.ENGLISH
}

export function startsWithSupportedLanguage(urlpath: string): boolean {
  const languages = Object.keys(Language)
  return languages.some((key) => urlpath.startsWith('/' + Language[key as keyof typeof Language]))
}

// Only to be called in setup
export function changeLanguage(language: Language = Language.ENGLISH) {
  changeHTMLLanguage(language)
  changeDayjsLanguage(language)
  changePrimeVueLanguage(language)
  changeI18nLanguage(language)
}

function changeHTMLLanguage(language: Language = Language.ENGLISH) {
  const langAttribute = document.documentElement.lang
  if (langAttribute !== language) {
    const html = document.querySelector('html')
    html?.setAttribute('lang', language)
  }
}

function getLanguageFromURLSegment(): Language | undefined {
  const url = new URL(window.location.href)
  const language = url.pathname.split('/')[1]
  if (isSupportedLanguage(language)) {
    return getLanguageEnum(language)
  }
}

function getLanguageFromBrowser(): Language | undefined {
  const language = navigator.language.split('-')[0]
  if (isSupportedLanguage(language)) {
    return getLanguageEnum(language)
  }
}

function isSupportedLanguage(language: string | undefined) {
  return Object.values(Language).includes(language?.toLowerCase() as Language)
}

function getLanguageEnum(language?: string | undefined): Language {
  switch (language) {
    case 'en':
      return Language.ENGLISH
    case 'nl':
      return Language.DUTCH
    default:
      return Language.ENGLISH
  }
}
