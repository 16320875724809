export const PREFIX = 'no-no'

export const color = {
  white: '#FFFFFF',
  black: '#303030',
  grey: '#808080',
  blue: '#004483',
  lightblue: '#B0DAF1',
  red: '#D64933',
  red50: '#F68063'
}

export const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

// minutes
export const LOGIN_EXPIRY_TIME = 60

export const CLEANUP_INTERVAL = 30 * 60 * 1000

export const INCIDENT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const INCIDENT_DATE_DISPLAY_FORMAT = 'YYYY MMMM DD'

// See PrimeFlex breakpoints
export const BREAKPOINT = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200
} as const
export type Breakpoint = (typeof BREAKPOINT)[keyof typeof BREAKPOINT]
