import { PREFIX } from '@/utils/constants'

const STORE_PREFIX = 'store'

export const AUTH_ID = `${PREFIX}-${STORE_PREFIX}-auth`
export const INCIDENT_FORM_ID = `${PREFIX}-${STORE_PREFIX}-incident-form`
export const INCIDENT_ID = `${PREFIX}-${STORE_PREFIX}-incidents`
export const ADDRESSES_STORE_ID = `${PREFIX}-${STORE_PREFIX}-addresses`
export const MENU_STORE_ID = `${PREFIX}-${STORE_PREFIX}-menu`
export const MAP_STORE_ID = `${PREFIX}-${STORE_PREFIX}-map`
