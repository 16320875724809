<template v-if="incident">
  <div class="pb-4" v-if="incident?.date">
    <p class="nn-text text-sm">{{ $t('forms.incidentForm.summary.date') }}</p>
    <p class="nn-text color-blue">{{ incidentDateFormatted(incident?.date || '') }}</p>
  </div>
  <div class="pb-4" v-if="incident?.method?.length">
    <p class="nn-text text-sm">{{ $t('forms.incidentForm.summary.whatHappened') }}</p>
    <p class="nn-text color-blue">{{ incidentMethodFormatted(incident?.method || []) }}</p>
  </div>
  <Person v-if="incident?.culprit" :person="incident.culprit" person-type="culprit" />
  <Person v-if="incident?.target" :person="incident.target" person-type="target" />
  <div class="pb-4" v-if="incident.description">
    <p class="nn-text text-sm">{{ $t('forms.incidentForm.summary.description') }}</p>
    <p class="nn-text color-blue preformatted">{{ incident?.description || '' }}</p>
  </div>
  <div class="pb-4" v-if="incident.location?.latitude && incident.location.longitude">
    <p class="nn-text text-sm">{{ $t('forms.incidentForm.summary.location') }}</p>
    <p v-if="placeName" class="nn-text color-blue">{{ placeName }}</p>
    <p v-if="address" class="nn-text color-blue">{{ address }}</p>
    <div
      class="flex flex-column justify-content-center align-content-center relative h-16rem md:w-30rem"
    >
      <nn-map
        :options="nnMapOptions"
        :markerOptions="nnMarkerOptions"
        :markers="markers"
        @ready="map = $event"
      >
      </nn-map>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { incidentDateFormatted, incidentMethodFormatted } from '@/stores/incident.store.fns'
import Person from './nn-incident.person.vue'
import { useAddressesStore } from '@/stores/addresses.store'
import type { IncidentDto, IncidentRequest } from '@/stores/incident.store.types'
import { GESTURE_HANDLING } from '@/models/google-maps'
import type { Ref } from 'vue'
import { useIncidentFormStore } from '@/stores'

interface Props {
  incident: IncidentDto | Partial<IncidentRequest>
}

const props = defineProps<Props>()

const formState = useIncidentFormStore()
const addressesStore = useAddressesStore()

const marker = ref()
const map: Ref<google.maps.Map | undefined> = ref()
const markers = ref()
const address = ref(formState.address)
const placeName = ref(formState.placeName)
const nnMapOptions = ref({
  zoomControl: true,
  mapTypeControl: false,
  fullscreenControl: false,
  rotateControl: false,
  streetViewControl: false,
  scaleControl: false,
  panControl: false,
  gestureHandling: GESTURE_HANDLING.NONE,
  clickableIcons: false,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  keyboardShortcuts: false,
  streetViewControL: false
})
const nnMarkerOptions = ref({
  clickable: false,
  draggable: false
})

const updateMarkerAndAddress = async () => {
  const latitude = props.incident.location?.latitude
  const longitude = props.incident.location?.longitude

  if (latitude && longitude) {
    marker.value = {
      position: {
        lat: latitude,
        lng: longitude
      }
    }

    markers.value = [
      {
        position: {
          lat: latitude,
          lng: longitude
        }
      }
    ]

    // try {
    //   const result = await fetchFromGeocode(latitude, longitude)
    //   placeName.value = result?.name
    //   address.value = result?.formatted_address
    // } catch (error) {
    //   console.error('Failed to get address:', error)
    // }
  }
}

watch(map, async (googleMap) => {
  if (!googleMap) return
  await updateMarkerAndAddress()
})

// async function fetchFromGeocode(lat: number, lng: number) {
//   try {
//     const geocode = await addressesStore.fetchGeocode({ lat, lng })
//     if (geocode.place_id && map.value) {
//       const nnPLace = await addressesStore.fetchPlaceDetails(geocode.place_id, map.value)
//       if (nnPLace) return nnPLace
//     }
//     return geocode
//   } catch (error) {
//     console.error(error)
//   }
// }
</script>
