<template>
  <pv-button
    v-if="blok"
    v-editable="blok"
    @click="navigate()"
    :label="blok?.name"
    class="p-button-link"
  />
</template>

<script setup lang="ts">
import { useMenuStore } from '@/stores'
import { openLinkUrl } from '@/storyblok/utils'
import type { SBLink } from '@/models/storyblok'

interface Props {
  blok: SBLink
}

const props = defineProps<Props>()

const menu = useMenuStore()

const navigate = () => {
  menu.isVisible = false

  const nnLink: SBLink = props.blok
  if (!nnLink) {
    return
  }

  openLinkUrl(nnLink)
}
</script>
