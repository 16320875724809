import type { App } from 'vue'
import type { ISbConfig } from '@storyblok/vue'
import { StoryblokVue, apiPlugin } from '@storyblok/vue'

export const defineStoryblok = (app: App) => {
  app.use(StoryblokVue, {
    accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
    apiOptions: {
      cache: {
        type: 'memory',
        clear: 'auto'
      }
    } as ISbConfig,
    bridge: import.meta.env.DEV,
    use: [apiPlugin]
  })
}
