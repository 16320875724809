import { acceptHMRUpdate, defineStore } from 'pinia'
import { INCIDENT_FORM_ID } from './constants'
import dayjs from 'dayjs'
import {
  METHOD,
  type Method,
  methodLocales,
  APPEARANCE,
  appearanceLocales,
  type Gender,
  genderLocales,
  ageGroupLocales,
  isIndividualLocales,
  type IsIndividual,
  IS_INDIVIDUAL
} from './incident.store.types'
import type { FormOption, FormOptionType, IncidentFormState } from './incident-form.store.types'
import type { Appearance } from './incident.store.types'
import { GENDER } from './incident.store.types'
import type { AgeGroup } from './incident.store.types'
import { AGE_GROUP } from './incident.store.types'
import { getTranslation } from '@/i18n/utils'
import { INCIDENT_DATE_DISPLAY_FORMAT, INCIDENT_DATE_FORMAT } from '@/utils/constants'
import { incidentPersonFormatted, isPersonComplex } from './incident.store.fns'

export const useIncidentFormStore = defineStore({
  id: INCIDENT_FORM_ID,
  state: (): IncidentFormState => ({ ...getDefaultState() }),
  getters: {
    getWhatHappened: (state) => {
      const t = getTranslation()

      const what = state.incidentRequest.method?.map((m) => {
        return t(methodLocales[m])
      })
      if (!what) {
        return
      }

      if (what.length > 1) {
        const lastElement = what.pop()
        const output = `${what.join(', ')} ${t('common.and')} ${lastElement}`
        return output
      }
      return what[0]
    },
    getWhatHappenedError: (state) => {
      return !hasSelectedItem(state.methods)
    },
    getDate: (state) => {
      return dayjs(state.incidentRequest.date, INCIDENT_DATE_FORMAT).toDate()
    },
    getDateFormatted: (state) => {
      return dayjs(state.incidentRequest.date, INCIDENT_DATE_FORMAT).format(
        INCIDENT_DATE_DISPLAY_FORMAT
      )
    },
    getDateError: (state) => {
      return !state.incidentRequest.date
    },
    getCulprit(): string | undefined {
      return incidentPersonFormatted(this.incidentRequest.culprit)
    },
    getCulpritTags(): string[] {
      const t = getTranslation()

      const appearance = this.getCulpritAppearance.map((m) => t(appearanceLocales[m]))
      const gender = this.getCulpritGender.map((m) => t(genderLocales[m]))
      const ageGroup = this.getCulpritAgeGroup.map((m) => t(ageGroupLocales[m]))
      return appearance.concat(gender, ageGroup)
    },
    isComplexCulprit(): boolean {
      return isPersonComplex(this.incidentRequest.culprit)
    },
    isComplexTarget(): boolean {
      return !this.incidentRequest.target.isIndividual && this.getTargetAppearance.length > 1
    },
    getCulpritCollective(state) {
      return state.culprit.isIndividual.filter((item) => item.selected)[0]
    },
    getCulpritCollectiveError: (state) => {
      return !hasSelectedItem(state.culprit.isIndividual)
    },
    getCulpritAppearance: (state) => {
      return state.incidentRequest.culprit.appearance || []
    },
    getCulpritAppearanceError: (state) => {
      if (state.incidentRequest.culprit.isIndividual) {
        return !hasOneSelectedItem(state.culprit.appearance)
      }
      return !hasSelectedItem(state.culprit.appearance)
    },
    getCulpritGender: (state) => {
      return state.incidentRequest.culprit.gender || []
    },
    getCulpritGenderError: (state) => {
      return !hasSelectedItem(state.culprit.gender)
    },
    getCulpritAgeGroup: (state) => {
      return state.incidentRequest.culprit.ageGroup || []
    },
    getCulpritAgeGroupError: (state) => {
      return !hasSelectedItem(state.culprit.ageGroup)
    },
    getTarget(): string | undefined {
      return incidentPersonFormatted(this.incidentRequest.culprit)
    },
    getTargetTags(): string[] {
      const t = getTranslation()

      const appearance = this.getTargetAppearance.map((m) => t(appearanceLocales[m]))
      const gender = this.getTargetGender.map((m) => t(genderLocales[m]))
      const ageGroup = this.getTargetAgeGroup.map((m) => t(ageGroupLocales[m]))
      return appearance.concat(gender, ageGroup)
    },
    getTargetCollective: (state) => {
      return state.target.isIndividual.filter((item) => item.selected)[0]
    },
    getTargetCollectiveError: (state) => {
      return !hasSelectedItem(state.target.isIndividual)
    },
    getTargetAppearance: (state) => {
      return state.incidentRequest.target.appearance || []
    },
    getTargetAppearanceError: (state) => {
      return !hasSelectedItem(state.target.appearance)
    },
    getTargetGender: (state) => {
      return state.incidentRequest.target.gender || []
    },
    getTargetGenderError: (state) => {
      return !hasSelectedItem(state.target.gender)
    },
    getTargetAgeGroup: (state) => {
      return state.incidentRequest.target.ageGroup || []
    },
    getTargetAgeGroupError: (state) => {
      return !hasSelectedItem(state.target.ageGroup)
    },
    getLocation: (state) => {
      return state.address
    },
    getLocationError: (state) => {
      return !state.address
    },
    getDescriptionError: (state) => {
      return (
        state.incidentRequest.description.length < 30 ||
        state.incidentRequest.description.length > 900
      )
    }
  },
  actions: {
    resetCulprit() {
      this.incidentRequest.culprit.appearance = resetToSingleOption(this.culprit.appearance)
      this.incidentRequest.culprit.gender = resetToSingleOption(this.culprit.gender) as Gender[]
      this.incidentRequest.culprit.ageGroup = resetToSingleOption(
        this.culprit.ageGroup
      ) as AgeGroup[]
    },
    resetTarget() {
      this.incidentRequest.target.appearance = resetToSingleOption(this.target.appearance)
      this.incidentRequest.target.gender = resetToSingleOption(this.target.gender) as Gender[]
      this.incidentRequest.target.ageGroup = resetToSingleOption(this.target.ageGroup) as AgeGroup[]
    },
    hasErrors() {
      return (
        this.getWhatHappenedError ||
        this.getDateError ||
        this.getCulpritCollectiveError ||
        this.getCulpritAppearanceError ||
        this.getCulpritGenderError ||
        this.getCulpritAgeGroupError ||
        this.getTargetCollectiveError ||
        this.getTargetAppearanceError ||
        this.getTargetGenderError ||
        this.getTargetAgeGroupError ||
        this.getLocationError ||
        this.getDescriptionError
      )
    },
    reset() {
      Object.assign(this.$state, { ...getDefaultState() })
    }
  },
  persist: {
    storage: sessionStorage
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIncidentFormStore, import.meta.hot))
}

function hasSelectedItem(options: FormOption<FormOptionType>[]): boolean {
  return options.some((option: any) => option.selected)
}

function hasOneSelectedItem(options: FormOption<FormOptionType>[]): boolean {
  return options.filter((option: any) => option.selected).length === 1
}

function hasMultipleSelectedItems(options: FormOption<FormOptionType>[]): boolean {
  return options.filter((option: any) => option.selected).length > 1
}

function getDefaultState(): IncidentFormState {
  const methods = mapToFormOptions<Method>(METHOD, methodLocales)
  const appearances = mapToFormOptions<Appearance>(APPEARANCE, appearanceLocales)
  const genders = mapToFormOptions<Gender>(GENDER, genderLocales)
  const ageGroups = mapToFormOptions<AgeGroup>(AGE_GROUP, ageGroupLocales)
  const isIndividualOptions = mapToFormOptions<IsIndividual>(IS_INDIVIDUAL, isIndividualLocales)

  // TODO: Find a better way to sort form options
  methods.sort((a: FormOption<Method>, b: FormOption<Method>) => {
    if (a.value === METHOD.PHYSICAL) return -1
    if (b.value === METHOD.PHYSICAL) return 1
    return 0
  })

  const clone = JSON.parse(
    JSON.stringify({
      incidentRequest: {
        date: dayjs().hour(0).minute(0).second(0).format(INCIDENT_DATE_FORMAT),
        description: '',
        disabled: false,
        location: {
          latitude: null,
          longitude: null
        },
        method: [],
        culprit: {
          isIndividual: null,
          ageGroup: [],
          gender: [],
          appearance: []
        },
        target: {
          isIndividual: null,
          ageGroup: [],
          gender: [],
          appearance: []
        }
      },
      methods: [...methods],
      address: '',
      culprit: {
        appearance: [...appearances],
        isIndividual: [...isIndividualOptions],
        gender: [...genders],
        ageGroup: [...ageGroups]
      },
      target: {
        appearance: [...appearances],
        isIndividual: [...isIndividualOptions],
        gender: [...genders],
        ageGroup: [...ageGroups]
      },
      currentStep: 0,
      submitted: false
    })
  )
  return clone
}

type LocaleStringRecord<T extends number | string> = Record<T, string>
function mapToFormOptions<T extends number | string>(
  record: Record<string, T>,
  localeStrings: LocaleStringRecord<T>
): FormOption<T>[] {
  return Object.entries(record).map(([, value]) => ({
    name: localeStrings[value],
    value,
    selected: false
  }))
}

function resetToSingleOption(
  options: FormOption<FormOptionType>[]
): Appearance[] | Gender[] | AgeGroup[] {
  if (hasMultipleSelectedItems(options)) {
    let isFirstSelectedFound = false

    for (const item of options) {
      if (isFirstSelectedFound) {
        item.selected = false
      } else if (item.selected) {
        isFirstSelectedFound = true
      }
    }
  }
  return options.filter((o) => o.selected).map((option) => option.value)
}
