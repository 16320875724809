import { getLanguage, startsWithSupportedLanguage } from '@/utils/language'
import { createRouter, createWebHistory, type Router, type RouteRecordRaw } from 'vue-router'

const mainPage = () => import('@/components/storyblok/content_types/nn-page.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: `/${getLanguage()}`
  },
  {
    path: '/:lang',
    children: [
      {
        path: '',
        name: 'home',
        component: mainPage
      },
      {
        path: ':section',
        children: [
          {
            path: '',
            component: mainPage
          },
          {
            path: ':id',
            component: mainPage
          }
        ]
      },
      {
        path: ':pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/components/nono/NotFound.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.path === import.meta.env.VITE_API_AUTHENTICATION_LINK) {
    return next()
  }

  if (!startsWithSupportedLanguage(to.path)) {
    const defaultLanguage = getLanguage()
    const newPath = '/' + defaultLanguage + to.path
    return next(newPath)
  }

  return next()
})

router.onError((error) => {
  console.error('Error while routing', error)
})

export function useRouter(): Router {
  return router
}
