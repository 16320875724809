import type { App } from 'vue'
import { createI18n } from 'vue-i18n'
import type { ComposerOptions, I18n } from 'vue-i18n'
import { Language } from '@/models/languages'
import * as nl from './locales/nl.json'
import * as en from './locales/en.json'

const messages = {
  [Language.DUTCH]: nl,
  [Language.ENGLISH]: en
}

export function defineI18n(app: App): I18n {
  const i18n = setupI18n()
  app.use(i18n)
  return i18n
}

export default function setupI18n(options: ComposerOptions = {}): I18n {
  return createI18n({
    legacy: false,
    globalInjection: true,
    locale: Language.ENGLISH,
    fallbackLocale: Language.DUTCH,
    messages,
    ...options
  })
}
