<template>
  <div class="mb-3 p-3 nn-section bg-black2">
    <p class="nn-text text-sm font-bold color-white2 pb-3">
      {{ $t('forms.incidentForm.messages.successMessage.content3') }}
    </p>
    <br>
    <p class="nn-text text-sm font-bold color-white2">
      {{ $t('forms.incidentForm.messages.successMessage.content4') }}
    </p>
    <ul>
      <li>
        <p class="nn-text text-sm color-white2">
          {{ $t('forms.incidentForm.messages.successMessage.content5') }}
        </p>
      </li>
      <li>
        <p class="nn-text text-sm color-white2">
          {{ $t('forms.incidentForm.messages.successMessage.content6') }}
        </p>
      </li>
      <li>
        <p class="nn-text text-sm color-white2">
          {{ $t('forms.incidentForm.messages.successMessage.content7') }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">

</script>
