<template>
  <div
    class="flex flex-column"
    v-if="blok"
    v-editable="blok"
    :id="blok._uid"
    :class="{
      'nn-incident-form-v2': true
    }"
  >
    <div
      class="card"
      :class="{
        'opacity-0': formState.currentStep >= 2
      }"
    >
      <ProgressBar class="mx-3 mt-3" :value="getProgress()" :showValue="false"></ProgressBar>
    </div>

    <TabView ref="IncidentForm" v-model:activeIndex="formState.currentStep">
      <!-- 1 -->
      <TabPanel header="{{ $t('forms.incidentForm.steps.v2.one.title') }}">
        <NNFormTitle
          :title="`${formState.currentStep + 1}. ${$t('forms.incidentForm.steps.v2.one.title')}`"
        ></NNFormTitle>
        <NNFormQuestion
          :errorCondition="formTriggers.culprit.appearance && formState.getCulpritAppearanceError"
          :title="$t('forms.incidentForm.steps.v2.one.culprit')"
        >
          <NNMultiSelect
            :blok="formState.culprit.appearance"
            :single="false"
            :translate="true"
            @change="culpritAppearanceChanged"
          ></NNMultiSelect>
          <NNFormMessage
            :title="$t('forms.incidentForm.messages.indigenousDescription')"
          ></NNFormMessage>
        </NNFormQuestion>

        <NNFormQuestion
          :errorCondition="formTriggers.target.appearance && formState.getTargetAppearanceError"
          :title="$t('forms.incidentForm.steps.v2.one.target')"
        >
          <NNMultiSelect
            :blok="formState.target.appearance"
            :single="false"
            :translate="true"
            @change="targetAppearanceChanged"
          ></NNMultiSelect>
          <NNFormMessage
            :title="$t('forms.incidentForm.messages.indigenousDescription')"
          ></NNFormMessage>
        </NNFormQuestion>

        <ErrorMessage
          :message="$t('forms.incidentForm.messages.requiredMessage')"
          v-show="
            (formTriggers.culprit && formState.getCulpritAppearanceError) ||
            (formTriggers.target && formState.getTargetAppearanceError)
          "
        >
        </ErrorMessage>
      </TabPanel>

      <!-- 2 -->
      <TabPanel header="{{ $t('forms.incidentForm.steps.v2.one.title') }}">
        <NNFormTitle
          :title="`${formState.currentStep + 1}. ${$t('forms.incidentForm.steps.v2.two.title')}`"
        ></NNFormTitle>

        <NNFormQuestion
          :errorCondition="formTriggers.date && formState.getDateError"
          :title="$t('forms.incidentForm.steps.v2.two.date')"
        >
          <NNMultiSelect
            :blok="dates"
            :single="true"
            :translate="false"
            @change="dateChanged"
          ></NNMultiSelect>
          <NNFormMessage :title="$t('forms.incidentForm.messages.dateRelevance')"></NNFormMessage>
        </NNFormQuestion>

        <NNFormQuestion
          :errorCondition="formTriggers.location && formState.getLocationError"
          :title="$t('forms.incidentForm.steps.v2.two.location')"
        >
          <div class="mb-3">
            <nn-map-input :nnPlace="nnPlace" @change="mapChanged"></nn-map-input>
          </div>
          <NNIncidentMapDetails ref="mapDetails"></NNIncidentMapDetails>
        </NNFormQuestion>

        <ErrorMessage
          :message="$t('forms.incidentForm.messages.requiredMessage')"
          v-show="
            (formTriggers.date && formState.getDateError) ||
            (formTriggers.location && formState.getLocationError)
          "
        >
        </ErrorMessage>
      </TabPanel>

      <!-- Summary -->
      <TabPanel header="{{ $t('forms.incidentForm.summary.title') }}">
        <NNIncidentFormSummary :form-triggers="formTriggers"></NNIncidentFormSummary>
        <div class="mb-3 p-3 nn-section border-1 border-blue-300">
          <p class="nn-text text-sm">{{ $t('forms.incidentForm.messages.submitNote') }}</p>
        </div>
      </TabPanel>

      <!-- Result -->
      <TabPanel header="{{ $t('forms.incidentForm.summary.title') }}">
        <NNIncidentFormResult></NNIncidentFormResult>
        <div class="flex flex-column align-items-center gap-3 px-3">
          <!-- v-if="incident" -->
          <!-- @click="goToIncidentSummary(incident.id)" -->
          <!-- link÷ -->
          <pv-button
            :label="$t('forms.incidentForm.summary.view_on_map')"
            size="large"
            class="nn-button w-auto flex-shrink-0"
            severity="secondary"
          />
        </div>
      </TabPanel>
    </TabView>

    <NNFormNavigation
      :submitted="false"
      :total-steps="2"
      :current-step="formState.currentStep"
      @back="navBack"
      @next="navNext"
      @submit="navSubmit"
    ></NNFormNavigation>
  </div>
</template>

<script setup lang="ts">
import NNFormTitle from '../common/nn-form-title.vue'
import NNFormQuestion from '../common/nn-form-question.vue'
import NNFormMessage from '../common/nn-form-message.vue'
import NNFormNavigation from '../common/nn-form-navigation.vue'
import NNIncidentFormSummary from './nn-incident-form.summary.vue'
import NNIncidentFormResult from './nn-incident-form.result.vue'
import NNIncidentMapDetails from '@/components/storyblok/apps/nn-incident/nn-incident-map.details.vue'

import type { StoryblokComponentType } from '@storyblok/vue'
import type { Appearance, IncidentDto } from '@/stores/incident.store.types'
import type { MultiSelectEvent } from '@/components/nono/MultiSelect.vue'

import { onMounted, ref, type Ref } from 'vue'
import dayjs from 'dayjs'

import { isObjectFilled, yearQuestionItems } from '../common/helpers'
import { appearanceChanged } from '../common/form'
import { useIncidentFormStore, useIncidentStore } from '@/stores'
import { INCIDENT_DATE_FORMAT } from '@/utils/constants'
import { scrollToTop, goToIncidentSummary } from '@/utils/utils'
import { detectTouchDevice } from '@/utils'
import type { NNPlace } from '@/models/google-maps'
import type { FormOption } from '@/stores/incident-form.store.types'
import { sanitizeLatLng } from '@/google-maps/utils'

export interface SBIncidentFormV2 extends StoryblokComponentType<string> {}

interface Props {
  blok: SBIncidentFormV2
}

defineProps<Props>()

let isMounted = ref(false)
const isTouchDevice = ref(false)

const mapDetails: Ref<{ mapDetails: HTMLDivElement } | null> = ref(null)

// Form
const formState = useIncidentFormStore()
const incidentState = useIncidentStore()
const incident: Ref<IncidentDto | undefined> = ref()

// Form error state triggers
const formTriggers: any = ref({ culprit: {}, target: {} })
formTriggers.value.culprit.appearance = formState.incidentRequest.culprit.appearance?.length > 0
formTriggers.value.target.appearance = formState.incidentRequest.target.appearance?.length > 0
formTriggers.value.date = !!formState.incidentRequest.date
formTriggers.value.location =
  formState.incidentRequest.location.latitude && formState.incidentRequest.location.longitude

// Culprit field
const culpritAppearanceChanged = ({ options }: { options: FormOption<Appearance>[] }) => {
  formTriggers.value.culprit.appearance = true
  appearanceChanged(
    {
      options,
      person: 'culprit'
    },
    formState
  )
}

// Target field
const targetAppearanceChanged = ({ options }: { options: FormOption<Appearance>[] }) => {
  formTriggers.value.target.appearance = true
  appearanceChanged(
    {
      options,
      person: 'target'
    },
    formState
  )
}

// Date field
const minDate = dayjs().subtract(2, 'years').toDate()
const maxDate = dayjs().endOf('day').toDate()
const dates = yearQuestionItems(minDate, maxDate)
const dateChanged = (selectEvent: MultiSelectEvent) => {
  formTriggers.value.date = true
  formState.incidentRequest.date = dayjs()
    .year(Number(selectEvent.option.value))
    .hour(0)
    .minute(0)
    .second(0)
    .format(INCIDENT_DATE_FORMAT)
}

// Location field
const location: Ref<
  | {
      position: {
        lat: number
        lng: number
      }
    }
  | undefined
> = ref()
const nnPlace: Ref<NNPlace | undefined> = ref()
const mapChanged = (place: NNPlace) => {
  formTriggers.value.location = true

  if (!place.geometry?.location) {
    return
  }

  const latLng = sanitizeLatLng(place.geometry?.location?.lat, place.geometry?.location?.lng)

  nnPlace.value = place

  location.value = {
    position: {
      lat: latLng.lat,
      lng: latLng.lng
    }
  }

  formState.address = place.formatted_address || ''
  formState.placeName = place.name
  formState.place_id = place.name
  formState.incidentRequest.location = {
    latitude: latLng.lat,
    longitude: latLng.lng
  }

  if (mapDetails.value?.mapDetails) {
    mapDetails.value.mapDetails.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }
}

const hasErrors = () => {
  switch (formState.currentStep) {
    case 0:
      return formState.getCulpritAppearanceError || formState.getTargetAppearanceError
    case 1:
      return formState.getDateError || formState.getLocationError
    default:
      return false
  }
}

const navBack = () => {
  formState.currentStep = formState.currentStep - 1
  scrollToTop()
}
const navNext = () => {
  switch (formState.currentStep) {
    case 0:
      // formTriggers.value.culprit.isIndividual = true
      formTriggers.value.culprit.appearance = true
      // formTriggers.value.target.isIndividual = true
      formTriggers.value.target.appearance = true
      break
    case 1:
      formTriggers.value.date = true
      formTriggers.value.location = true
      break
  }
  if (!hasErrors()) {
    formState.currentStep = formState.currentStep + 1
    scrollToTop()
  }
}
const navSubmit = async () => {
  // for now only validating that the to be submitted object is filled
  if (isObjectFilled(formState.incidentRequest)) {
    // incident.value = await incidentState.postIncident(formState.incidentRequest)
    if (incident.value?.id) {
      formState.submitted = true
    }
  }
  navNext()
}

const getProgress = () => {
  const base = (100 - 5) / 2

  switch (formState.currentStep) {
    case 0:
      if (formState.getCulpritAppearance?.length && formState.getTargetAppearance?.length) {
        return base
      }
      if (formState.getCulpritAppearance?.length || formState.getTargetAppearance?.length) {
        return base / 2
      }
      return 0
    case 1: {
      if (formState.address) {
        return base * 2
      }
      return base
    }
    case 2:
      if (formState.submitted) {
        return 100
      }
      return 95
    default:
      return 0
  }
}

onMounted(() => {
  isTouchDevice.value = detectTouchDevice()
  isMounted.value = true
})
</script>

<style lang="scss">
$red: #d64933;
$blue: #004483;
$light-blue: #b0daf1;

.p-progressbar.p-progressbar-determinate {
  height: 10px;
  border: 1px $blue solid;
  border-radius: 2px;
  background-color: transparent;

  .p-progressbar-value {
    background: $blue;
  }
}
</style>
