<template>
  <pv-scroll-top />

  <div v-if="story?.content" v-editable="story.content" class="flex flex-column h-screen">
    <header class="flex-grow-0">
      <nn-header v-show="story?.content?.showHeader"></nn-header>
      <component v-show="story?.content?.showMenu" :is="nnMenu" />
    </header>
    <main class="flex-grow-1">
      <StoryblokComponent v-for="block in story?.content?.blocks" :key="block._uid" :blok="block" />
    </main>
    <nn-footer class="flex-grow-0" v-show="story?.content?.showFooter"></nn-footer>
  </div>
</template>

<script setup lang="ts">
import { useStoryblokBridge, type ISbStoryData } from '@storyblok/vue'
import nnMenu from '@/components/storyblok/content_types/nn-menu.vue'
import { onMounted, ref } from 'vue'
import { getStory, getStoryLanguage, getStoryName } from '@/utils'

const language = getStoryLanguage()
const name = getStoryName()

const story = ref<ISbStoryData>()

onMounted(async () => {
  await getStory(name, language)
    .then((data) => {
      story.value = data?.value
    })
    .catch((error) => {
      // page not found
      console.error('Error loading Page', error)
    })

  if (!story.value) {
    story.value = await getStory('not-found', language).then((data) => {
      return data?.value
    })
  }

  if (story.value) {
    useStoryblokBridge(story.value.id, (updatedStory) => (story.value = updatedStory))
  }
})
</script>
