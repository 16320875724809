<template>
  <div
    v-if="formState.placeName || formState.address"
    ref="mapDetails"
    class="pb-4 p-3 nn-section bg-light-blue"
  >
    <p class="nn-text text-sm underline">{{ $t('common.selectedLocation') }}</p>
    <p v-if="formState.placeName" class="nn-text color-blue">{{ formState.placeName }}</p>
    <p v-if="formState.address" class="nn-text color-blue">{{ formState.address }}</p>
  </div>
</template>

<script setup lang="ts">
import { useIncidentFormStore } from '@/stores'
import type { Ref } from 'vue'
import { ref } from 'vue'

const mapDetails: Ref<HTMLDivElement | null> = ref(null)

defineExpose({
  mapDetails
})

const formState = useIncidentFormStore()
</script>
