import { usePrimeVue } from 'primevue/config'
import { Language } from '@/models/languages'
import * as nl from './locales/nl.json'
import * as en from './locales/en.json'

export function changePrimeVueLanguage(language: Language = Language.ENGLISH) {
  const primevue = usePrimeVue()

  let locales = en
  if (language === Language.DUTCH) {
    locales = nl
  }

  primevue.config.locale = locales.locale
}
